import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { useTranslation } from 'react-i18next'
import {
  extractEmailFromUrl,
  containsNoValues,
  checkValidations,
  passwordRule,
  requiredRule
} from 'src/libs/qb-brand-web-components'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'

import ResetPassword from '../components/resetPassword/ResetPassword'
import { LOG_IN_URL } from '../constants/navigation'
import requireUnauth from '../hoc/requireUnauth'
import {
  changeResetPasswordState,
  changeResetPasswordInputAndErrorState,
  cleanResetPasswordState,
  resetPassword
} from '../store/actions/auth'
import { RESET_PASSWORD_PROFILE_DISPATCH } from '../store/reducers/auth/resetPassword'
import { ResetSuccess } from '../components/popups/resetPassword'

const ResetPasswordContainer = ({
  resetPasswordProfile,
  changeResetPasswordState,
  changeResetPasswordInputAndErrorState,
  cleanResetPasswordState,
  resetPassword
}) => {
  const { t } = useTranslation()

  const navigate = useNavigate()
  const location = useLocation()
  const [searchParams] = useSearchParams()

  useEffect(() => {
    return () => {
      cleanResetPasswordState()
    }
  }, [])

  const onFormSubmit = () => {
    changeResetPasswordState(RESET_PASSWORD_PROFILE_DISPATCH.ERROR, {})
    const { newPassword } = resetPasswordProfile
    const errors = checkValidations({
      newPassword: { value: newPassword, rules: [requiredRule, passwordRule] }
    })
    if (containsNoValues(errors)) {
      const resetCode = searchParams.get('resetCode')
      if (resetCode) {
        const email = extractEmailFromUrl(location.search)
        resetPassword(email, resetCode, newPassword)
      }
    } else {
      changeResetPasswordState(RESET_PASSWORD_PROFILE_DISPATCH.ERROR, errors)
    }
  }

  const handleGoToLogin = () => {
    navigate(LOG_IN_URL)
  }

  return (
    <>
      <ResetPassword
        resetPasswordProfile={resetPasswordProfile}
        onFormSubmit={onFormSubmit}
        dispatchAction={changeResetPasswordInputAndErrorState}
        t={t}
      />
      {resetPasswordProfile.resetSuccess && (
        <ResetSuccess onSubmit={handleGoToLogin} t={t} />
      )}
    </>
  )
}

const mapStateToProps = ({ authReducer }) => {
  const { resetPasswordProfile } = authReducer
  return {
    resetPasswordProfile
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      changeResetPasswordState,
      changeResetPasswordInputAndErrorState,
      cleanResetPasswordState,
      resetPassword
    },
    dispatch
  )

export default requireUnauth(
  connect(mapStateToProps, mapDispatchToProps)(ResetPasswordContainer)
)
