import React, { memo } from 'react'
import {
  BWText,
  CSS_GAP_0,
  BW_COLOR_ACCENT
} from 'src/libs/qb-brand-web-components'

import Popup from '../../shared/popup/Popup'
import customConfig from 'customConfig'
import { exchangeProviderLabel } from '../../../util/pointsExchange.helpers'
import brandConfig from 'brandConfig'
import { formatTicks } from '../../../util/string.helpers'

const ReceivePoints = ({
  title,
  description,
  color,
  onSubmit,
  onCancel,
  children,
  t,
  name,
  symbol,
  cancelLabel = customConfig.goBackLabel,
  icon,
  value,
  additionalDesc,
  ...restProps
}) => {
  return (
    <Popup
      title={title}
      description={description}
      icon={icon}
      color={color || BW_COLOR_ACCENT}
      onFormSubmit={onSubmit}
      cancelLabel={onCancel && t(cancelLabel)}
      onCancel={onCancel && onCancel}
      contentCss={CSS_GAP_0}
      {...restProps}
    >
      {additionalDesc}
      {value && (
        <>
          <BWText
            label={formatTicks(value)}
            size={50}
            weight={800}
            alignCenter
          />
          <BWText
            label={
              brandConfig.brandSymbol
                ? exchangeProviderLabel({ name })
                : exchangeProviderLabel({ name, symbol })
            }
            alignCenter
          />
        </>
      )}
      {children && children}
    </Popup>
  )
}

export default memo(ReceivePoints)
