import React, { memo } from 'react'
import { BWTitlePanel } from 'src/libs/qb-brand-web-components'

import brandConfig from 'brandConfig'

const PageHeadingWrapper = (props) => {
  return (
    <BWTitlePanel
      color={brandConfig.prefColor}
      textColor={brandConfig.prefTextColor}
      {...props}
    />
  )
}

export default memo(PageHeadingWrapper)
