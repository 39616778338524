import React, { memo } from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/react'

import BWText from '../BWText'
import {
  cssFontSize,
  cssGap,
  cssPadding,
  CSS_FULL_HEIGHT,
  CSS_CENTERED_COLUMN_GRID,
  CSS_FULL_WIDTH
} from '../../styles/shared'
import { fontSizes, SIZE_UNIT } from '../../themes'
import BWIcon from '../BWIcon'

const BWNoRecordsFound = ({ icon, description }) => (
  <MessageContainer data-testid='bw-no-records-found'>
    {icon && (
      <BWIcon
        icon={icon}
        faIconCss={faIconCss()}
        size={fontSizes[SIZE_UNIT.XL6]}
      />
    )}
    <BWText alignCenter label={description} color='gray' />
  </MessageContainer>
)

export default memo(BWNoRecordsFound)

const faIconCss = () => css`
  ${cssFontSize(SIZE_UNIT.XL4)};
`

const MessageContainer = styled.div`
  ${CSS_CENTERED_COLUMN_GRID};
  ${CSS_FULL_HEIGHT};
  ${CSS_FULL_WIDTH};
  align-content: center;
  color: ${({ theme }) => theme.colors?.gray};
  ${cssGap(SIZE_UNIT.MD)};
  ${cssPadding(SIZE_UNIT.XL2)};
`
