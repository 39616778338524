import React, { memo } from 'react'
import { faScrollOld } from '@fortawesome/pro-light-svg-icons'

import { QUESTS_URL } from '../../constants/navigation'
import OverviewNavPanel from './OverviewNavPanel'

const QuestsButton = ({ t }) => {
  return (
    <OverviewNavPanel
      icon={faScrollOld}
      label={t('quest.quests')}
      to={QUESTS_URL}
      iconCss={{ fontSize: 28 }}
      headingCss={{ flexDirection: 'row' }}
    />
  )
}

export default memo(QuestsButton)
