import moment from 'moment'

const formatDate = (value, formatting, _) => {
  return moment.utc(value).local().format(formatting)
}

const nextTargetTime = (day = '', hour) => {
  const currentDate = moment.utc()
  // Get the next Wednesday at 2 PM UTC
  let nextWeek = moment
    .utc()
    .day(day)
    .hour(hour)
    .minute(0)
    .second(0)
    .millisecond(0)
  if (nextWeek.isBefore(currentDate)) {
    nextWeek = nextWeek.add(7, 'days')
  }
  // Calculate the time delta
  return formatTimeDelta(nextWeek.diff(currentDate))
}

const formatTimeDelta = (timeDelta) => {
  // Convert milliseconds to days, hours, and minutes
  // const days = Math.floor(timeDelta / (1000 * 60 * 60 * 24))
  const hours = Math.floor(
    (timeDelta % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  )
  const minutes = Math.floor((timeDelta % (1000 * 60 * 60)) / (1000 * 60))
  const seconds = Math.floor((timeDelta % (1000 * 60)) / 1000)

  // Format the result
  return `${hours}h ${minutes}m ${seconds}s`
  // @TODO: commented days, if needed adjust this function and reuse
  // return `${days}d ${hours}h ${minutes}m ${seconds}s`
}

export { nextTargetTime, formatDate, formatTimeDelta }
