import React, { memo } from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/react'

import {
  CSS_GRID,
  CSS_ITEMS_CENTER,
  CSS_CURSOR_POINTER,
  CSS_TEXT_CAPITALIZE,
  disabledStyles,
  CSS_FLEX
} from '../../styles/shared'
import BWMenuIcon from './BWMenuIcon'
import { getColorByLuminosity } from '../../util/style.helpers'

export const BWMenuItem = ({
  label,
  onClick,
  icon,
  isEmail,
  isOutside,
  direction = 'ltr',
  customCss,
  to,
  filterIcon,
  ...restProps
}) => {
  return isEmail || isOutside ? (
    <MenuItemContainer
      data-testid='bw-menu-item'
      as='a'
      href={isEmail ? `mailto:${to}` : to}
      target='_blank'
      rel='noopener noreferrer'
      css={customCss}
      {...restProps}
    >
      <span>{label}</span>
      {icon && <BWMenuIcon icon={icon} />}
    </MenuItemContainer>
  ) : (
    <MenuItemContainer
      data-testid='bw-menu-item'
      onClick={onClick && onClick}
      direction={direction}
      css={customCss}
      to={to}
      {...restProps}
    >
      <IconContainer>
        {filterIcon && <BWMenuIcon icon={filterIcon} />}
        <span style={{ flex: 1 }}>{label}</span>
      </IconContainer>
      {icon && <BWMenuIcon icon={icon} />}
    </MenuItemContainer>
  )
}

export default memo(BWMenuItem)

const MenuItemContainer = styled.li`
  ${CSS_GRID};
  ${CSS_CURSOR_POINTER};
  padding: 12px 0px;
  gap: 12px;
  ${CSS_ITEMS_CENTER};
  list-style-type: none;
  grid-template-columns: 1fr auto;
  text-align: right;
  &,
  &:active,
  :visited,
  :focus {
    color: inherit;
  }
  ${CSS_TEXT_CAPITALIZE};
  ${(props) => menuItemStyles(props)}
`

const IconContainer = styled.div`
  ${CSS_FLEX};
  ${CSS_ITEMS_CENTER};
  justify-content: space-between;
`

const menuItemStyles = ({ theme: { colors }, direction, disabled }) => css`
  border-top: 1px solid ${getColorByLuminosity(colors.accent, colors)};
  text-decoration: none;
  direction: ${direction};
  ${disabled && disabledStyles(colors)};
`
