import React, { memo } from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/react'

import {
  cssFontSize,
  cssBoxShadow,
  cssFontWeight,
  cssBorderRadius,
  CSS_HEIGHT_40,
  CSS_OUTLINE_NONE,
  CSS_TEXT_UPPERCASE,
  CSS_POSITION_RELATIVE
} from '../../styles/shared'
import { SIZE_UNIT } from '../../themes/units'
import {
  hexToRGBA,
  getCustomThemeColor,
  getCustomTextColor,
  getCustomTextColorByDefaultThemeColor
} from '../../util/style.helpers'

const BWButton = ({
  label,
  onClick,
  disabled,
  textTransform = CSS_TEXT_UPPERCASE,
  customCss,
  isRectangular = false,
  ...restProps
}) => {
  return (
    <ButtonContainer
      data-testid='bw-button'
      onClick={disabled ? null : onClick}
      disabled={disabled}
      css={[
        textTransform,
        customCss,
        !isRectangular && cssBorderRadius(SIZE_UNIT.XL4)
      ]}
      {...restProps}
    >
      {label}
    </ButtonContainer>
  )
}

BWButton.defaultProps = {
  type: 'button'
}

export default memo(BWButton)

const ButtonContainer = styled.button`
  padding-left: 10px;
  padding-right: 10px;
  ${cssFontWeight(SIZE_UNIT.LG)};
  ${cssFontSize(SIZE_UNIT.MD)};
  ${CSS_HEIGHT_40};
  ${CSS_POSITION_RELATIVE};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  &:focus,
  &:active {
    ${CSS_OUTLINE_NONE};
  }
  ${({ color, theme: { colors }, disabled, variant, textColor }) =>
    renderBtnStyles(color, colors, disabled, variant, textColor)};
  width: ${({ fullWidth, noFixed }) => getButtonWidth({ fullWidth, noFixed })};
`

const renderBtnStyles = (color, colors, disabled, variant, textColor) => {
  const themeColor = getCustomThemeColor({ disabled, colors, color })
  if (variant === 'border') {
    return borderBtnStyles({ colors, disabled, themeColor, color: textColor })
  }
  return defaultBtnStyles({ colors, disabled, themeColor, color: textColor })
}

const borderBtnStyles = ({ colors, disabled, themeColor, color }) => {
  const textColor = getCustomTextColorByDefaultThemeColor({
    disabled,
    colors,
    color,
    themeColor
  })
  return css`
    ${commonBtnStyles(disabled, themeColor)};
    background-color: ${hexToRGBA(themeColor, 0)};
    background-image: linear-gradient(
      90deg,
      '#17C0EB',
      '#510B39',
      '#E63F45',
      '#FFD9BD'
    );
    color: ${textColor};
    &:hover {
      background-color: ${!disabled && hexToRGBA(themeColor, 10)};
    }
  `
}

const defaultBtnStyles = ({ colors, disabled, themeColor, color }) => {
  const textColor = getCustomTextColor({ disabled, colors, color, themeColor })
  return css`
    ${commonBtnStyles(disabled, themeColor)};
    color: ${textColor};
    background-color: ${themeColor};
    &:hover {
      ${!disabled && cssBoxShadow(colors)};
    }
  `
}

const commonBtnStyles = (disabled, themeColor) => css`
  border: 2px solid ${themeColor};
  ${disabled && `background-image: none !important`};
  cursor: ${disabled ? 'default' : 'pointer'};
`

const getButtonWidth = ({ fullWidth, noFixed }) => {
  if (fullWidth) {
    return '100%'
  } else if (noFixed) {
    return 'fit-content'
  }
  return '255px'
}
