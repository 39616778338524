import { mergeToNewObject } from 'src/libs/qb-brand-web-components'

import { REWARD_BOX_ACTION } from '../../../constants/actions'
import { findExchangeMilestone } from '../../../util/rewardBox.helpers'

export const milestoneProfile = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case REWARD_BOX_ACTION.CHANGE_STATE:
      return mergeToNewObject(state, { [payload.property]: payload.value })

    case REWARD_BOX_ACTION.GET_MILESTONE_SUCCESS:
      const { data, txnData } = payload
      const achievedIndividual = txnData?.count
      return mergeToNewObject(state, {
        data,
        showPopup: false,
        achievedIndividual:
          achievedIndividual < state.achievedIndividual
            ? state.achievedIndividual
            : achievedIndividual
      })

    case REWARD_BOX_ACTION.INCREASE_MILESTONE:
      const exchangeMilestone = findExchangeMilestone(state.data)
      const increasedCount = state.achievedIndividual + 1
      return mergeToNewObject(state, {
        showPopup: increasedCount > exchangeMilestone?.threshold ? false : true,
        achievedIndividual: increasedCount
      })

    case REWARD_BOX_ACTION.GET_JACKPOT_WINNER_SUCCESS:
      return mergeToNewObject(state, {
        jackpotWinner: payload?.[0]
      })

    default:
      return state
  }
}

const INITIAL_STATE = {
  data: {
    community: [],
    individual: []
  },
  showPopup: false,
  achievedIndividual: 0,
  jackpotWinner: ''
}

export const MILESTONE_PROFILE_DISPATCH = {
  SHOW_POPUP: 'showPopup'
}
