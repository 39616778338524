import React, { memo } from 'react'
import {
  BWLink,
  BWPanelContent,
  cssCustomGap
} from 'src/libs/qb-brand-web-components'
import styled from '@emotion/styled'

import customConfig from 'customConfig'

const OverviewEnterCode = ({
  howToEarnLink,
  howDoesItWorkButton,
  t,
  onEnterRewardCodeClick
}) => {
  return (
    <CustomGridContent>
      {howToEarnLink}
      <BWLink
        label={t(customConfig.overviewEnterRewardCodeLabel)}
        onClick={onEnterRewardCodeClick}
      />
      {howDoesItWorkButton}
    </CustomGridContent>
  )
}

export default memo(OverviewEnterCode)

const CustomGridContent = styled(BWPanelContent)`
  ${cssCustomGap(15)};
`
