import React, { memo } from 'react'

import customConfig from 'customConfig'
import Button from '../../../components/shared/button/Button'

const OverviewHowToEarnWrapper = ({ t, onClick }) => {
  return <Button label={t(customConfig.howToEarnLabel)} onClick={onClick} />
}

export default memo(OverviewHowToEarnWrapper)
