import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { useNavigate } from 'react-router-dom'

import PageNotFound from '../components/pageNotFound/PageNotFound'
import { LOG_IN_URL, HOME_URL, ACCOUNT_URL } from '../constants/navigation'
import { getCurrentUser } from '../store/actions/auth'
import requireLanguage from '../hoc/requireLanguage'
import { welcomeScreenLink } from '../util/siteLink.helpers'
import { isAuthenticated } from '../util/account.helpers'
import { isExchangeAvailable } from '../util/pointsExchange.helpers'
import UnauthorizedLayoutWrapper from 'wrappers/UnauthorizedLayoutWrapper'
import AuthorizedLayoutContainer from './AuthorizedLayoutContainer'

const PageNotFoundContainer = ({ authState, getCurrentUser, ...restProps }) => {
  const checkIsAuthenticated = isAuthenticated(authState)
  const navigate = useNavigate()

  useEffect(() => {
    if (!checkIsAuthenticated) {
      getCurrentUser()
    }
  }, [checkIsAuthenticated, getCurrentUser])

  const handleBackToHome = () => {
    navigate(checkIsAuthenticated ? HOME_URL : welcomeScreenLink(), {
      replace: true
    })
  }

  const handleCancelLink = () => {
    navigate(checkIsAuthenticated ? ACCOUNT_URL : LOG_IN_URL, { replace: true })
  }

  if (checkIsAuthenticated) {
    return (
      <AuthorizedLayoutContainer {...restProps}>
        <PageNotFound
          auth
          onBackToHome={handleBackToHome}
          onCancelLink={handleCancelLink}
        />
      </AuthorizedLayoutContainer>
    )
  } else {
    return (
      <UnauthorizedLayoutWrapper>
        <PageNotFound
          onBackToHome={handleBackToHome}
          onCancelLink={handleCancelLink}
        />
      </UnauthorizedLayoutWrapper>
    )
  }
}

const mapStateToProps = ({
  authReducer,
  accountReducer,
  mainReducer,
  nftReducer
}) => {
  const { authProfile } = authReducer
  const { accountProfile } = accountReducer
  const { mainProfile, tokenProfile } = mainReducer
  const { brandAppConfig, exchangeWhitelist } = mainProfile
  const { nftProfile } = nftReducer
  return {
    authState: authProfile.authState,
    accountProfile,
    isExchangeEnabled: isExchangeAvailable(exchangeWhitelist),
    isNftEnabled: Boolean(nftProfile.nftsForRedemption.length),
    brandAppConfig,
    enablePurchasePoints: tokenProfile.enablePurchasePoints
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getCurrentUser
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(requireLanguage(PageNotFoundContainer))
