import React, { memo } from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/react'

import {
  cssFontSize,
  cssFontWeight,
  cssGap,
  cssPadding,
  CSS_ALIGN_CONTENT_START,
  CSS_TEXT_ALIGN_CENTER,
  CSS_TEXT_UPPERCASE,
  CSS_HEIGHT_AUTO,
  CSS_POSITION_RELATIVE
} from '../../styles/shared'
import { SIZE_UNIT } from '../../themes'
import { PanelContainer } from '.'
import BWText from '../BWText'
import { BW_COLOR_ACCENT } from '../../constants'
import BWIcon from '../BWIcon'
import { FrontCenterContent } from '../shared/styled/containers'

const BWTitlePanel = ({
  label,
  description,
  icon,
  header,
  textUppercase,
  link,
  iconColor,
  disabled,
  bgIcon,
  iconCss,
  headingCss,
  titleCss,
  ...restProps
}) => {
  const isHeader = header ? 1 : 0
  return (
    <TitlePanelContainer
      data-testid='bw-title-panel'
      disabled={disabled}
      {...restProps}
    >
      {bgIcon}
      <FrontCenterContent css={headingCss}>
        {icon && (
          <BWIcon
            faIconCss={[faIconCss(isHeader), iconCss]}
            moonIconCss={moonIconCss()}
            icon={icon}
            iconColor={iconColor}
            disabled={disabled}
          />
        )}
        <PanelLabel
          header={isHeader}
          textUppercase={textUppercase}
          css={titleCss}
        >
          {label}
        </PanelLabel>
        {description && <BWText alignCenter label={description} />}
        {link && link}
      </FrontCenterContent>
    </TitlePanelContainer>
  )
}

BWTitlePanel.defaultProps = {
  color: BW_COLOR_ACCENT,
  anchor: false
}

export default memo(BWTitlePanel)

const TitlePanelContainer = styled(PanelContainer)`
  ${cssGap(SIZE_UNIT.SM)};
  ${cssPadding(SIZE_UNIT.MD)};
  ${CSS_ALIGN_CONTENT_START};
  ${CSS_HEIGHT_AUTO};
  ${CSS_POSITION_RELATIVE};
`
const faIconCss = (header) => css`
  ${cssFontSize(header ? SIZE_UNIT.XL5 : SIZE_UNIT.XL2)};
`

const moonIconCss = () => css`
  width: 80px;
  height: 100px;
  margin: -30px -30px -25px -30px;
`

const PanelLabel = styled.div`
  ${cssFontWeight(SIZE_UNIT.LG)};
  ${({ header, textUppercase }) => titleStyles(header, textUppercase)};
  ${CSS_TEXT_ALIGN_CENTER};
`

const titleStyles = (header, textUppercase) => css`
  ${header && cssFontSize(header ? SIZE_UNIT.LG : SIZE_UNIT.MD)};
  ${textUppercase && CSS_TEXT_UPPERCASE};
`
