// AUTH
export const AUTH_SIGNUP_ACTION = {
  CHANGE_STATE: 'AUTH_SIGNUP_ACTION_CHANGE_STATE',
  CHANGE_INPUT_AND_ERROR_STATE:
    'AUTH_SIGNUP_ACTION_CHANGE_INPUT_AND_ERROR_STATE',
  RESET_STATE: 'AUTH_SIGNUP_ACTION_RESET_STATE',

  ON_SIGN_UP: 'AUTH_SIGNUP_ACTION_ON_SIGN_UP',
  ON_SIGN_UP_SUCCESS: 'AUTH_SIGNUP_ACTION_ON_SIGN_UP_SUCCESS',
  ON_SIGN_UP_FAILED: 'AUTH_SIGNUP_ACTION_ON_SIGN_UP_FAILED',

  ON_RESEND_EMAIL: 'AUTH_SIGNUP_ACTION_ON_RESEND_EMAIL',
  ON_RESEND_EMAIL_SUCCESS: 'AUTH_SIGNUP_ACTION_ON_RESEND_EMAIL_SUCCESS',
  ON_RESEND_EMAIL_FAILED: 'AUTH_SIGNUP_ACTION_ON_RESEND_EMAIL_FAILED',

  ON_CONFIRM: 'AUTH_SIGNUP_ACTION_ON_CONFIRM',
  ON_CONFIRM_SUCCESS: 'AUTH_SIGNUP_ACTION_ON_CONFIRM_SUCCESS',
  ON_CONFIRM_FAILED: 'AUTH_SIGNUP_ACTION_ON_CONFIRM_FAILED'
}

export const AUTH_LOGIN_ACTION = {
  CHANGE_STATE: 'AUTH_LOGIN_ACTION_CHANGE_STATE',
  CHANGE_INPUT_AND_ERROR_STATE:
    'AUTH_LOGIN_ACTION_CHANGE_INPUT_AND_ERROR_STATE',
  RESET_STATE: 'AUTH_LOGIN_ACTION_RESET_STATE',

  ON_LOG_IN: 'AUTH_LOGIN_ACTION_ON_LOG_IN',
  ON_LOG_IN_SUCCESS: 'AUTH_LOGIN_ACTION_ON_LOG_IN_SUCCESS',
  ON_LOG_IN_FAILED: 'AUTH_LOGIN_ACTION_ON_LOG_IN_FAILED',

  ON_SOCIAL_MEDIA_LOG_IN: 'AUTH_LOGIN_ACTION_ON_SOCIAL_MEDIA_LOG_IN',
  ON_SOCIAL_MEDIA_LOG_IN_SUCCESS:
    'AUTH_LOGIN_ACTION_ON_SOCIAL_MEDIA_LOG_IN_SUCCESS',
  ON_SOCIAL_MEDIA_LOG_IN_FAILED:
    'AUTH_LOGIN_ACTION_ON_SOCIAL_MEDIA_LOG_IN_FAILED'
}

export const AUTH_FORGOT_PASSWORD_ACTION = {
  CHANGE_STATE: 'AUTH_FORGOT_PASSWORD_ACTION_CHANGE_STATE',
  CHANGE_INPUT_AND_ERROR_STATE:
    'AUTH_FORGOT_PASSWORD_ACTION_CHANGE_INPUT_AND_ERROR_STATE',
  RESET_STATE: 'AUTH_FORGOT_PASSWORD_ACTION_RESET_STATE',

  ON_REQUEST_PASSWORD_CHANGE:
    'AUTH_FORGOT_PASSWORD_ACTION_ON_REQUEST_PASSWORD_CHANGE',
  ON_REQUEST_PASSWORD_CHANGE_SUCCESS:
    'AUTH_FORGOT_PASSWORD_ACTION_ON_REQUEST_PASSWORD_CHANGE_SUCCESS',
  ON_REQUEST_PASSWORD_CHANGE_FAILED:
    'AUTH_FORGOT_PASSWORD_ACTION_ON_REQUEST_PASSWORD_CHANGE_FAILED'
}

export const AUTH_RESET_PASSWORD_ACTION = {
  CHANGE_STATE: 'AUTH_RESET_PASSWORD_ACTION_CHANGE_STATE',
  CHANGE_INPUT_AND_ERROR_STATE:
    'AUTH_RESET_PASSWORD_ACTION_CHANGE_INPUT_AND_ERROR_STATE',
  RESET_STATE: 'AUTH_RESET_PASSWORD_ACTION_RESET_STATE',

  ON_RESET_PASSWORD: 'AUTH_RESET_PASSWORD_ACTION_ON_RESET_PASSWORD',
  ON_RESET_PASSWORD_SUCCESS:
    'AUTH_RESET_PASSWORD_ACTION_ON_RESET_PASSWORD_SUCCESS',
  ON_RESET_PASSWORD_FAILED:
    'AUTH_RESET_PASSWORD_ACTION_ON_RESET_PASSWORD_FAILED'
}

export const AUTH_ACTION = {
  ON_CHANGE_PASSWORD: 'AUTH_ACTION_ON_CHANGE_PASSWORD',
  ON_CHANGE_PASSWORD_SUCCESS: 'AUTH_ACTION_ON_CHANGE_PASSWORD_SUCCESS',
  ON_CHANGE_PASSWORD_FAILED: 'AUTH_ACTION_ON_CHANGE_PASSWORD_FAILED',

  ON_GET_CURRENT_USER: 'AUTH_ACTION_ON_GET_CURRENT_USER',
  ON_GET_CURRENT_USER_SUCCESS: 'AUTH_ACTION_ON_GET_CURRENT_USER_SUCCESS',
  ON_GET_CURRENT_USER_FAILED: 'AUTH_ACTION_ON_GET_CURRENT_USER_FAILED',

  ON_GET_CURRENT_USER_AFTER_UPDATE_SUCCESS:
    'AUTH_ACTION_ON_GET_CURRENT_USER_AFTER_UPDATE_SUCCESS',
  ON_GET_CURRENT_USER_AFTER_UPDATE_FAILED:
    'AUTH_ACTION_ON_GET_CURRENT_USER_AFTER_UPDATE_FAILED',

  ON_SIGN_OUT: 'AUTH_ACTION_ON_SIGN_OUT',
  ON_SIGN_OUT_SUCCESS: 'AUTH_ACTION_ON_SIGN_OUT_SUCCESS',
  ON_SIGN_OUT_FAILED: 'AUTH_ACTION_ON_SIGN_OUT_FAILED',

  ON_UPDATE_USER_ATTRIBUTES: 'AUTH_ACTION_ON_UPDATE_USER_ATTRIBUTES',
  ON_UPDATE_USER_ATTRIBUTES_SUCCESS:
    'AUTH_ACTION_ON_UPDATE_USER_ATTRIBUTES_SUCCESS',
  ON_UPDATE_USER_ATTRIBUTES_FAILED:
    'AUTH_ACTION_ON_UPDATE_USER_ATTRIBUTES_FAILED',

  ON_UPDATE_EMAIL_MISSING: 'AUTH_ACTION_ON_UPDATE_EMAIL_MISSING',
  ON_CLOSE_EMAIL_MISSING: 'AUTH_ACTION_ON_CLOSE_EMAIL_MISSING',

  ON_ACCEPT_TERMS: 'AUTH_ACTION_ON_ACCEPT_TERMS',
  ON_ACCEPT_TERMS_SUCCESS: 'AUTH_ACTION_ON_ACCEPT_TERMS_SUCCESS',
  ON_ACCEPT_TERMS_FAILED: 'AUTH_ACTION_ON_ACCEPT_TERMS_FAILED',

  ON_GET_CURRENT_USER_AFTER_TERMS_ACCEPTANCE_SUCCESS:
    'AUTH_ACTION_ON_GET_CURRENT_USER_AFTER_TERMS_ACCEPTANCE_SUCCESS',
  ON_GET_CURRENT_USER_AFTER_TERMS_ACCEPTANCE_FAILED:
    'AUTH_ACTION_ON_GET_CURRENT_USER_AFTER_TERMS_ACCEPTANCE_FAILED'
}

// REWARDS ACTION
export const REWARDS_ACTION = {
  CHANGE_STATE: 'REWARDS_ACTION_CHANGE_STATE',
  CHANGE_INPUT_AND_ERROR_STATE: 'REWARDS_ACTION_CHANGE_INPUT_AND_ERROR_STATE',
  RESET_STATE: 'REWARDS_ACTION_RESET_STATE',

  ON_VALIDATE_REWARD_CODE: 'REWARDS_ACTION_ON_VALIDATE_REWARD_CODE',
  ON_REWARD_CODE_EXISTS: 'REWARDS_ACTION_ON_REWARD_CODE_EXISTS',
  ON_REWARD_CODE_DOES_NOT_EXIST: 'REWARDS_ACTION_ON_REWARD_CODE_DOES_NOT_EXIST',

  ON_RECEIVE_REWARD_FROM_CODE: 'REWARDS_ACTION_ON_RECEIVE_REWARD_FROM_CODE',
  ON_RECEIVE_REWARD_FROM_CODE_FAILED:
    'REWARDS_ACTION_ON_RECEIVE_REWARD_FROM_CODE_FAILED'
}

// POINTS REWARD ACTION
export const POINTS_REWARD_ACTION = {
  ON_RECEIVE_REWARD_FROM_BRAND_POINTS:
    'POINTS_REWARD_ACTION_ON_RECEIVE_REWARD_FROM_BRAND_POINTS',
  ON_RECEIVE_REWARD_FROM_BRAND_POINTS_SUCCESS:
    'POINTS_REWARD_ACTION_ON_RECEIVE_REWARD_FROM_BRAND_POINTS_SUCCESS',
  ON_RECEIVE_REWARD_FROM_BRAND_POINTS_FAILED:
    'POINTS_REWARD_ACTION_ON_RECEIVE_REWARD_FROM_BRAND_POINTS_FAILED',

  ON_RECEIVE_POINTS_FROM_CODE_SUCCESS:
    'POINTS_REWARD_ACTION_ON_RECEIVE_POINTS_FROM_CODE_SUCCESS'
}

// TRANSACTIONS HISTORY
export const TRANSACTIONS_HISTORY = {
  CHANGE_STATE: 'TRANSACTIONS_HISTORY_CHANGE_STATE',

  ON_GET_TRANSACTIONS: 'TRANSACTIONS_HISTORY_ON_GET_TRANSACTIONS',
  ON_GET_TRANSACTIONS_SUCCESS:
    'TRANSACTIONS_HISTORY_ON_GET_TRANSACTIONS_SUCCESS',
  ON_GET_TRANSACTIONS_FAILED: 'TRANSACTIONS_HISTORY_ON_GET_TRANSACTIONS_FAILED'
}

export const MAIN_PROFILE_ACTION = {
  ON_LOAD_MAIN_DATA: 'MAIN_PROFILE_ACTION_ON_LOAD_MAIN_DATA',
  ON_LOAD_MAIN_DATA_SUCCESS: 'MAIN_PROFILE_ACTION_ON_LOAD_MAIN_DATA_SUCCESS',
  ON_LOAD_MAIN_DATA_FAILED: 'MAIN_PROFILE_ACTION_ON_LOAD_MAIN_DATA_FAILED',

  CHANGE_STATE: 'MAIN_PROFILE_ACTION_CHANGE_STATE',
  CHANGE_INPUT_AND_ERROR_STATE:
    'MAIN_PROFILE_ACTION_CHANGE_INPUT_AND_ERROR_STATE',

  REFRESH_POINTS_BALANCE_SUCCESS:
    'MAIN_PROFILE_ACTION_REFRESH_POINTS_BALANCE_SUCCESS',
  REFRESH_POINTS_BALANCE_FAILED:
    'MAIN_PROFILE_ACTION_REFRESH_POINTS_BALANCE_FAILED',

  RESET_PREVIOUS_USER_POINTS_STATE:
    'MAIN_PROFILE_ACTION_RESET_PREVIOUS_USER_POINTS',

  ON_GET_BRAND_CONFIG: 'MAIN_PROFILE_ACTION_ON_GET_BRAND_CONFIG',
  ON_GET_BRAND_CONFIG_SUCCESS:
    'MAIN_PROFILE_ACTION_ON_GET_BRAND_CONFIG_SUCCESS',
  ON_GET_BRAND_CONFIG_FAILED: 'MAIN_PROFILE_ACTION_ON_GET_BRAND_CONFIG_FAILED',

  ON_CHECK_DISABLED_CAMPAIGN: 'MAIN_PROFILE_ACTION_ON_CHECK_DISABLED_CAMPAIGN'
}

// ACCOUNT ACTION
export const ACCOUNT_ACTION = {
  CHANGE_STATE: 'ACCOUNT_ACTION_CHANGE_STATE',
  RESET_STATE: 'ACCOUNT_ACTION_RESET_STATE',
  CHANGE_INPUT_AND_ERROR_STATE: 'ACCOUNT_ACTION_CHANGE_INPUT_AND_ERROR_STATE',

  GET_ACCOUNT_DETAILS: 'ACCOUNT_ACTION_GET_ACCOUNT_DETAILS',
  ON_GET_ACCOUNT_DETAILS_SUCCESS:
    'ACCOUNT_ACTION_ON_GET_ACCOUNT_DETAILS_SUCCESS',
  ON_GET_ACCOUNT_DETAILS_FAILED: 'ACCOUNT_ACTION_ON_GET_ACCOUNT_DETAILS_FAILED',

  ON_GET_USER_COUNTRY_SUCCESS: 'ACCOUNT_ACTION_ON_GET_USER_COUNTRY_SUCCESS',
  ON_GET_USER_COUNTRY_FAILED: 'ACCOUNT_ACTION_ON_GET_USER_COUNTRY_FAILED',

  ON_CREATE_ACCOUNT_SUCCESS: 'ACCOUNT_ACTION_ON_CREATE_ACCOUNT_SUCCESS',
  ON_CREATE_ACCOUNT_FAILED: 'ACCOUNT_ACTION_ON_CREATE_ACCOUNT_FAILED',

  ON_UPDATE_USER_ACCOUNT: 'ACCOUNT_ACTION_ON_UPDATE_USER_ACCOUNT',
  ON_UPDATE_USER_ACCOUNT_SUCCESS:
    'ACCOUNT_ACTION_ON_UPDATE_USER_ACCOUNT_SUCCESS',
  ON_UPDATE_USER_ACCOUNT_FAILED: 'ACCOUNT_ACTION_ON_UPDATE_USER_ACCOUNT_FAILED',

  ON_CAPTURE_DEVICE_DETAILS: 'ACCOUNT_ACTION_ON_CAPTURE_DEVICE_DETAILS',
  ON_CAPTURE_DEVICE_DETAILS_SUCCESS:
    'ACCOUNT_ACTION_ON_CAPTURE_DEVICE_DETAILS_SUCCESS',
  ON_CAPTURE_DEVICE_DETAILS_FAILED:
    'ACCOUNT_ACTION_ON_CAPTURE_DEVICE_DETAILS_FAILED'
}

// EXCHANGE PROVIDER ACTION
export const EXCHANGE_PROVIDER_ACTION = {
  CHANGE_STATE: 'EXCHANGE_PROVIDER_ACTION_CHANGE_STATE',
  CHANGE_INPUT_AND_ERROR_STATE:
    'EXCHANGE_PROVIDER_ACTION_CHANGE_INPUT_AND_ERROR_STATE',
  RESET_STATE: 'EXCHANGE_PROVIDER_ACTION_RESET_STATE',

  ON_CONNECT_EXCHANGE_PROVIDER:
    'EXCHANGE_PROVIDER_ACTION_ON_CONNECT_EXCHANGE_PROVIDER',
  ON_CONNECT_EXCHANGE_PROVIDER_SUCCESS:
    'EXCHANGE_PROVIDER_ACTION_ON_CONNECT_EXCHANGE_PROVIDER_SUCCESS',
  ON_CONNECT_EXCHANGE_PROVIDER_FAILED:
    'EXCHANGE_PROVIDER_ACTION_ON_CONNECT_EXCHANGE_PROVIDER_FAILED',

  ON_UPDATE_EXCHANGE_PROVIDER:
    'EXCHANGE_PROVIDER_ACTION_ON_UPDATE_EXCHANGE_PROVIDER',
  ON_UPDATE_EXCHANGE_PROVIDER_SUCCESS:
    'EXCHANGE_PROVIDER_ACTION_ON_UPDATE_EXCHANGE_PROVIDER_SUCCESS',
  ON_UPDATE_EXCHANGE_PROVIDER_FAILED:
    'EXCHANGE_PROVIDER_ACTION_ON_UPDATE_EXCHANGE_PROVIDER_FAILED',

  ON_GET_USER_MEMBERSHIPS_SUCCESS:
    'EXCHANGE_PROVIDER_ACTION_ON_GET_USER_MEMBERSHIPS_SUCCESS',
  ON_GET_USER_MEMBERSHIPS_FAILED:
    'EXCHANGE_PROVIDER_ACTION_ON_GET_USER_MEMBERSHIPS_FAILED',

  ON_GET_REWARD_TYPES_FOR_BRAND:
    'EXCHANGE_PROVIDER_ACTION_ON_GET_REWARD_TYPES_FOR_BRAND',
  ON_GET_REWARD_TYPES_FOR_BRAND_SUCCESS:
    'EXCHANGE_PROVIDER_ACTION_ON_GET_REWARD_TYPES_FOR_BRAND_SUCCESS',
  ON_GET_REWARD_TYPES_FOR_BRAND_FAILED:
    'EXCHANGE_PROVIDER_ACTION_ON_GET_REWARD_TYPES_FOR_BRAND_FAILED'
}

export const SURVEY_ACTION = {
  CHANGE_STATE: 'SURVEY_ACTION_CHANGE_STATE',
  CHANGE_INPUT_AND_ERROR_STATE: 'SURVEY_ACTION_CHANGE_INPUT_AND_ERROR_STATE',

  ON_SUBMIT_FAVORITE_EXCHANGE: 'SURVEY_ACTION_ON_SUBMIT_FAVORITE_EXCHANGE',
  ON_SUBMIT_FAVORITE_EXCHANGE_SUCCESS:
    'SURVEY_ACTION_ON_SUBMIT_FAVORITE_EXCHANGE_SUCCESS',
  ON_SUBMIT_FAVORITE_EXCHANGE_FAILED:
    'SURVEY_ACTION_ON_SUBMIT_FAVORITE_EXCHANGE_FAILED',

  ON_GET_USER_SURVEY_SUBMISSIONS:
    'SURVEY_ACTION_ON_GET_USER_SURVEY_SUBMISSIONS',
  ON_GET_USER_SURVEY_SUBMISSIONS_SUCCESS:
    'SURVEY_ACTION_ON_GET_USER_SURVEY_SUBMISSIONS_SUCCESS',
  ON_GET_USER_SURVEY_SUBMISSIONS_FAILED:
    'SURVEY_ACTION_ON_GET_USER_SURVEY_SUBMISSIONS_FAILED',

  ON_GET_SURVEYS: 'SURVEY_ACTION_ON_GET_SURVEYS',
  ON_GET_SURVEYS_SUCCESS: 'SURVEY_ACTION_ON_GET_SURVEYS_SUCCESS',
  ON_GET_SURVEYS_FAILED: 'SURVEY_ACTION_ON_GET_SURVEYS_FAILED',

  ON_SUBMIT_REDEMPTION_FULFILLMENT:
    'SURVEY_ACTION_ON_SUBMIT_REDEMPTION_FULFILLMENT',
  ON_SUBMIT_REDEMPTION_FULFILLMENT_SUCCESS:
    'SURVEY_ACTION_ON_SUBMIT_REDEMPTION_FULFILLMENT_SUCCESS',
  ON_SUBMIT_REDEMPTION_FULFILLMENT_FAILED:
    'SURVEY_ACTION_ON_SUBMIT_REDEMPTION_FULFILLMENT_FAILED'
}

export const REDEMPTION_FULFILLMENT_ACTION = {
  RESET_STATE: 'REDEMPTION_FULFILLMENT_ACTION_RESET_STATE',
  CHANGE_STATE: 'REDEMPTION_FULFILLMENT_ACTION_CHANGE_STATE',
  CHANGE_INPUT_AND_ERROR_STATE:
    'REDEMPTION_FULFILLMENT_ACTION_CHANGE_INPUT_AND_ERROR_STATE'
}

// EXCHANGE ACTION
export const EXCHANGE_ACTION = {
  ON_RECEIVE_EXTERNAL_REWARD_FROM_BRAND_POINTS:
    'EXCHANGE_ACTION_ON_RECEIVE_EXTERNAL_REWARD_FROM_BRAND_POINTS',
  ON_RECEIVE_EXTERNAL_REWARD_FROM_BRAND_POINTS_SUCCESS:
    'EXCHANGE_ACTION_ON_RECEIVE_EXTERNAL_REWARD_FROM_BRAND_POINTS_SUCCESS',
  ON_RECEIVE_EXTERNAL_REWARD_FROM_BRAND_POINTS_FAILED:
    'EXCHANGE_ACTION_ON_RECEIVE_EXTERNAL_REWARD_FROM_BRAND_POINTS_FAILED',

  ON_START_CRYPTO_EXCHANGE_RATES_POLLING:
    'EXCHANGE_ACTION_ON_START_CRYPTO_EXCHANGE_RATES_POLLING',
  ON_GET_CRYPTO_EXCHANGE_RATES_SUCCESS:
    'EXCHANGE_ACTION_ON_GET_CRYPTO_EXCHANGE_RATES_SUCCESS',
  ON_GET_CRYPTO_EXCHANGE_RATES_FAILED:
    'EXCHANGE_ACTION_ON_GET_CRYPTO_EXCHANGE_RATES_FAILED',
  ON_STOP_CRYPTO_EXCHANGE_RATES_POLLING:
    'EXCHANGE_ACTION_ON_STOP_CRYPTO_EXCHANGE_RATES_POLLING'
}

// BUY_POINTS ACTION
export const BUY_POINTS_ACTION = {
  CHANGE_STATE: 'BUY_POINTS_ACTION_CHANGE_STATE',

  ON_GENERATE_CHECKOUT_SESSION:
    'BUY_POINTS_ACTION_ON_GENERATE_CHECKOUT_SESSION',
  ON_GENERATE_CHECKOUT_SESSION_SUCCESS:
    'BUY_POINTS_ACTION_ON_GENERATE_CHECKOUT_SESSION_SUCCESS',
  ON_GENERATE_CHECKOUT_SESSION_FAILED:
    'BUY_POINTS_ACTION_ON_GENERATE_CHECKOUT_SESSION_FAILED',

  ON_GET_PAYMENT_SESSION: 'BUY_POINTS_ACTION_ON_GET_PAYMENT_SESSION',
  ON_GET_PAYMENT_SESSION_SUCCESS:
    'BUY_POINTS_ACTION_ON_GET_PAYMENT_SESSION_SUCCESS',
  ON_GET_PAYMENT_SESSION_FAILED:
    'BUY_POINTS_ACTION_ON_GET_PAYMENT_SESSION_FAILED'
}

// NFT ACTION
export const NFT_ACTION = {
  RESET_STATE: 'NFT_ACTION_RESET_STATE',
  ON_RECEIVE_NFT_FROM_CODE_SUCCESS:
    'NFT_ACTION.ON_RECEIVE_NFT_FROM_CODE_SUCCESS',
  CHANGE_NFT_ACTION_STATE: 'NFT_ACTION_CHANGE_NFT_ACTION_STATE',

  RESET_REFRESH_MEMBERSHIP_STATE: 'NFT_ACTION_RESET_REFRESH_MEMBERSHIP_STATE',

  BUY_NFT: 'NFT_ACTION_BUY_NFT',
  BUY_NFT_SUCCESS: 'NFT_ACTION_BUY_NFT_SUCCESS',
  BUY_NFT_FAILED: 'NFT_ACTION_BUY_NFT_FAILED',

  GET_NFT_CLAIMS: 'NFT_ACTION_GET_NFT_CLAIMS',
  GET_NFT_CLAIMS_SUCCESS: 'NFT_ACTION_GET_NFT_CLAIMS_SUCCESS',
  GET_NFT_CLAIMS_FAILED: 'NFT_ACTION_GET_NFT_CLAIMS_FAILED',

  ON_GET_EXCLUSIVE_CONTENT: 'NFT_ACTION_ON_GET_EXCLUSIVE_CONTENT',
  ON_GET_EXCLUSIVE_CONTENT_SUCCESS:
    'NFT_ACTION_ON_GET_EXCLUSIVE_CONTENT_SUCCESS',
  ON_GET_EXCLUSIVE_CONTENT_FAILED: 'NFT_ACTION_ON_GET_EXCLUSIVE_CONTENT_FAILED',

  ON_REFRESH_MEMBERSHIP: 'NFT_ACTION_ON_REFRESH_MEMBERSHIP',
  ON_REFRESH_MEMBERSHIP_SUCCESS: 'NFT_ACTION_ON_REFRESH_MEMBERSHIP_SUCCESS',
  ON_REFRESH_MEMBERSHIP_FAILED: 'NFT_ACTION_ON_REFRESH_MEMBERSHIP_FAILED',

  GET_NFT_BALANCES: 'NFT_ACTION_GET_NFT_BALANCES',
  GET_NFT_BALANCES_SUCCESS: 'NFT_ACTION_GET_NFT_BALANCES_SUCCESS',
  GET_NFT_BALANCES_FAILED: 'NFT_ACTION_GET_NFT_BALANCES_FAILED',

  REFRESH_NFT_BALANCES_SUCCESS: 'NFT_ACTION_REFRESH_NFT_BALANCES_SUCCESS'
}

// ENGRAVING ACTION
export const ENGRAVING_ACTION = {
  ON_GET_ENGRAVINGS: 'ENGRAVING_ACTION_ON_GET_ENGRAVINGS',
  ON_GET_ENGRAVINGS_SUCCESS: 'ENGRAVING_ACTION_ON_GET_ENGRAVINGS_SUCCESS',
  ON_GET_ENGRAVINGS_FAILED: 'ENGRAVING_ACTION_ON_GET_ENGRAVINGS_FAILED'
}

// TRANSFER NFT
export const TRANSFER_NFT_ACTION = {
  CHANGE_STATE: 'TRANSFER_NFT_ACTION_CHANGE_STATE',
  CHANGE_INPUT_AND_ERROR_STATE:
    'TRANSFER_NFT_ACTION_CHANGE_INPUT_AND_ERROR_STATE',
  RESET_STATE: 'TRANSFER_NFT_ACTION_RESET_STATE',

  TRANSFER_NFT: 'TRANSFER_NFT_ACTION_TRANSFER_NFT',
  TRANSFER_NFT_SUCCESS: 'TRANSFER_NFT_ACTION_TRANSFER_NFT_SUCCESS',
  TRANSFER_NFT_FAILED: 'TRANSFER_NFT_ACTION_TRANSFER_NFT_FAILED'
}

// SELL NFT
export const SELL_NFT_ACTION = {
  CHANGE_STATE: 'SELL_NFT_ACTION_CHANGE_STATE',
  CHANGE_INPUT_AND_ERROR_STATE: 'SELL_NFT_ACTION_CHANGE_INPUT_AND_ERROR_STATE',
  RESET_STATE: 'SELL_NFT_ACTION_RESET_STATE',

  LIST_NFT_FOR_SALE: 'SELL_NFT_ACTION_LIST_NFT_FOR_SALE',
  LIST_NFT_FOR_SALE_SUCCESS: 'SELL_NFT_ACTION_LIST_NFT_FOR_SALE_SUCCESS',
  LIST_NFT_FOR_SALE_FAILED: 'SELL_NFT_ACTION_LIST_NFT_FOR_SALE_FAILED',

  GET_LISTED_NFTS: 'SELL_NFT_ACTION_GET_LISTED_NFTS',
  GET_LISTED_NFTS_SUCCESS: 'SELL_NFT_ACTION_GET_LISTED_NFTS_SUCCESS',
  GET_LISTED_NFTS_FAILED: 'SELL_NFT_ACTION_GET_LISTED_NFTS_FAILED',

  GET_LISTED_NFT_BY_ID: 'SELL_NFT_ACTION_GET_LISTED_NFT_BY_ID',
  GET_LISTED_NFT_BY_ID_SUCCESS: 'SELL_NFT_ACTION_GET_LISTED_NFT_BY_ID_SUCCESS',
  GET_LISTED_NFT_BY_ID_FAILED: 'SELL_NFT_ACTION_GET_LISTED_NFT_BY_ID_FAILED',

  CANCEL_NFT_LISTING: 'SELL_NFT_ACTION_CANCEL_NFT_LISTING',
  CANCEL_NFT_LISTING_SUCCESS: 'SELL_NFT_ACTION_CANCEL_NFT_LISTING_SUCCESS',
  CANCEL_NFT_LISTING_FAILED: 'SELL_NFT_ACTION_CANCEL_NFT_LISTING_FAILED'
}

// REDEEM ACTIONS
export const REDEEM_ACTION = {
  RESET_STATE: 'REDEEM_ACTION_RESET_STATE',
  ON_GET_REWARD_TYPES: 'REDEEM_ACTION_ON_GET_REWARD_TYPES',
  ON_GET_REWARD_TYPES_SUCCESS: 'REDEEM_ACTION_ON_GET_REWARD_TYPES_SUCCESS',
  ON_GET_REWARD_TYPES_FAILED: 'REDEEM_ACTION_ON_GET_REWARD_TYPES_FAILED',

  ON_GET_SIGNED_URL: 'REDEEM_ACTION_ON_GET_SIGNED_URL',
  ON_GET_SIGNED_URL_SUCCESS: 'REDEEM_ACTION_ON_GET_SIGNED_URL_SUCCESS',
  ON_GET_SIGNED_URL_FAILED: 'REDEEM_ACTION_ON_GET_SIGNED_URL_FAILED'
}

// CASHBACK ACTION
export const CASHBACK_ACTION = {
  CHANGE_STATE: 'CASHBACK_ACTION_CHANGE_STATE',
  RESET_STATE: 'CASHBACK_ACTION_RESET_STATE',
  CHANGE_INPUT_STATE: 'CASHBACK_ACTION_CHANGE_INPUT_STATE',
  ON_GET_CASHBACK_BRANDS: 'CASHBACK_ACTION_ON_GET_CASHBACK_BRANDS',
  ON_GET_CASHBACK_BRANDS_SUCCESS:
    'CASHBACK_ACTION_ON_GET_CASHBACK_BRANDS_SUCCESS',
  ON_GET_CASHBACK_BRANDS_FAILED:
    'CASHBACK_ACTION_ON_GET_CASHBACK_BRANDS_FAILED',
  ON_GET_CASHBACK_BRANDS_BY_SEARCH:
    'CASHBACK_ACTION_ON_GET_CASHBACK_BRANDS_BY_SEARCH',
  ON_GET_CASHBACK_BRANDS_BY_SEARCH_SUCCESS:
    'CASHBACK_ACTION_ON_GET_CASHBACK_BRANDS_BY_SEARCH_SUCCESS',
  ON_GET_CASHBACK_BRANDS_BY_SEARCH_FAILED:
    'CASHBACK_ACTION_ON_GET_CASHBACK_BRANDS_BY_SEARCH_FAILED',
  ON_GET_CASHBACK_FILTER_CATEGORIES:
    'CASHBACK_ACTION_ON_GET_CASHBACK_FILTER_CATEGORIES',
  ON_GET_CASHBACK_FILTER_CATEGORIES_SUCCESS:
    'CASHBACK_ACTION_ON_GET_CASHBACK_FILTER_CATEGORIES_SUCCESS',
  ON_GET_CASHBACK_FILTER_CATEGORIES_FAILED:
    'CASHBACK_ACTION_ON_GET_CASHBACK_FILTER_CATEGORIES_FAILED',
  ON_GET_CASHBACK_BRAND_DETAILS:
    'CASHBACK_ACTION_ON_GET_CASHBACK_BRAND_DETAILS',
  ON_GET_CASHBACK_BRAND_DETAILS_SUCCESS:
    'CASHBACK_ACTION_ON_GET_CASHBACK_BRAND_DETAILS_SUCCESS',
  ON_GET_CASHBACK_BRAND_DETAILS_FAILED:
    'CASHBACK_ACTION_ON_GET_CASHBACK_BRAND_DETAILS_FAILED',

  ON_SELECT_CATEGORY: 'CASHBACK_ACTION_ON_SELECT_CATEGORY',
  ON_SELECTED_CATEGORY: 'CASHBACK_ACTION_ON_SELECTED_CATEGORY'
}

// WALLET ACTIONS
export const WALLET_ACTION = {
  RESET_STATE: 'WALLET_ACTION_RESET_STATE',
  ON_GET_WALLET: 'WALLET_ACTION_ON_GET_WALLET',
  ON_GET_WALLET_SUCCESS: 'WALLET_ACTION_ON_GET_WALLET_SUCCESS',
  ON_GET_WALLET_FAILED: 'WALLET_ACTION_ON_GET_WALLET_FAILED',

  UPDATE_WALLET_ADDRESS: 'WALLET_ACTION_UPDATE_WALLET_ADDRESS'
}

// STAKING ACTIONS
export const STAKING_ACTION = {
  RESET_STATE: 'STAKING_ACTION_RESET_STATE',
  CHANGE_STATE: 'STAKING_ACTION_CHANGE_STATE',

  ON_GET_NFT_COUNT: 'WALLET_ACTION_ON_GET_NFT_COUNT',
  ON_GET_NFT_COUNT_SUCCESS: 'WALLET_ACTION_ON_GET_NFT_COUNT_SUCCESS',
  ON_GET_NFT_COUNT_FAILED: 'WALLET_ACTION_ON_GET_NFT_COUNT_FAILED',

  ON_GET_CLAIM_STATUS: 'STAKING_ACTION_ON_GET_CLAIM_STATUS',
  ON_GET_CLAIM_STATUS_SUCCESS: 'STAKING_ACTION_ON_GET_CLAIM_STATUS_SUCCESS',
  ON_GET_CLAIM_STATUS_FAILED: 'STAKING_ACTION_ON_GET_CLAIM_STATUS_FAILED',

  ON_CLAIM_STAKE: 'STAKING_ACTION_ON_CLAIM_STAKE',
  ON_CLAIM_STAKE_SUCCESS: 'STAKING_ACTION_ON_CLAIM_STAKE_SUCCESS',
  ON_CLAIM_STAKE_FAILED: 'STAKING_ACTION_ON_CLAIM_STAKE_FAILED'
}

// BANNERS ACTIONS
export const BANNERS_ACTION = {
  GET_BANNERS_GROUPING_DATA: 'BANNERS_ACTION_GET_BANNERS_GROUPING_DATA',
  GET_BANNERS_GROUPING_DATA_SUCCESS:
    'BANNERS_ACTION_GET_BANNERS_GROUPING_DATA_SUCCESS',
  GET_BANNERS_GROUPING_DATA_FAILED:
    'BANNERS_ACTION_GET_BANNERS_GROUPING_DATA_FAILED'
}

// QUEST ACTIONS
export const QUEST_ACTION = {
  RESET_STATE: 'QUEST_ACTION_RESET_STATE',
  CHANGE_STATE: 'QUEST_ACTION_CHANGE_STATE',

  UPDATE_QUEST: 'QUEST_ACTION_UPDATE_QUEST',
  UPDATE_QUEST_SUCCESS: 'QUEST_ACTION_UPDATE_QUEST_SUCCESS',
  UPDATE_QUEST_FAILED: 'QUEST_ACTION_UPDATE_QUEST_FAILED'
}

// REWARD_BOX ACTIONS
export const REWARD_BOX_ACTION = {
  CHANGE_STATE: 'REWARD_BOX_ACTION_CHANGE_STATE',

  INCREASE_MILESTONE: 'REWARD_BOX_ACTION_INCREASE_MILESTONE',

  GET_MILESTONE: 'REWARD_BOX_ACTION_GET_MILESTONE',
  GET_MILESTONE_SUCCESS: 'REWARD_BOX_ACTION_GET_MILESTONE_SUCCESS',
  GET_MILESTONE_FAILED: 'REWARD_BOX_ACTION_GET_MILESTONE_FAILED',

  GET_JACKPOT_WINNER: 'REWARD_BOX_ACTION_GET_JACKPOT_WINNER',
  GET_JACKPOT_WINNER_SUCCESS: 'REWARD_BOX_ACTION_GET_JACKPOT_WINNER_SUCCESS',
  GET_JACKPOT_WINNER_FAILED: 'REWARD_BOX_ACTION_GET_JACKPOT_WINNER_FAILED',

  START_JACKPOT_WINNER_POLLING:
    'REWARD_BOX_ACTION_START_JACKPOT_WINNER_POLLING',
  STOP_JACKPOT_WINNER_POLLING: 'REWARD_BOX_ACTION_STOP_JACKPOT_WINNER_POLLING'
}

// AIRDROP ACTIONS
export const AIRDROP_ACTION = {
  GET_TAP_QUEST: 'AIRDROP_ACTION_GET_TAP_QUEST',
  GET_TAP_QUEST_SUCCESS: 'AIRDROP_ACTION_GET_TAP_QUEST_SUCCESS',
  GET_TAP_QUEST_FAILED: 'AIRDROP_ACTION_GET_TAP_QUEST_FAILED'
}
