import { createStore, compose, applyMiddleware } from 'redux'
import { createEpicMiddleware } from 'redux-observable'

import { getRootReducer } from './reducers'
import { getRootEpic } from './epics'

const getIndexStore = async () => {
  const rootReducer = await getRootReducer()
  const rootEpic = await getRootEpic()

  const epicMiddleware = createEpicMiddleware()
  const composeEnhancers =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

  const store = createStore(
    rootReducer,
    composeEnhancers(applyMiddleware(epicMiddleware))
  )

  epicMiddleware.run(rootEpic)

  return store
}

export { getIndexStore }
