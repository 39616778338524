import {
  BW_AUTH_LANGUAGE_ATTR,
  stringToBool
} from 'src/libs/qb-brand-web-components'
import { mergeMap } from 'rxjs/operators'

import {
  AUTH_FIRST_NAME_ATTR,
  AUTH_LAST_NAME_ATTR,
  AUTH_REWARD_CODE_ATTR,
  AUTH_TERMS_ACCEPTED_ATTR
} from '../constants/auth'
import { APP_CONFIG } from '../constants/appConfig'
import { SIGNUP_PROCESS_STEP } from '../constants/containerStates'
import i18n from '../i18n'
import authService from '../services/auth'
import { extractSignupProfile } from './epics.helpers'
import brandConfig from 'brandConfig'
import { getCountryISO3 } from '../data/getCountryISO3'

export function extractCognitoUser(state) {
  const { authReducer } = state.value
  const { cognitoUser } = authReducer.authProfile
  return cognitoUser
}

export function extractCognitoUserAttributes(cognitoUser) {
  return cognitoUser.attributes
}

export function extractCognitoUserRewardCode(cognitoUser) {
  return extractCognitoUserAttributes(cognitoUser)?.[AUTH_REWARD_CODE_ATTR]
}

export const extractAccessToken = (cognitoUser) => {
  return cognitoUser?.accessToken?.jwtToken || ''
}

export function extractCognitoUserUsername(state) {
  return extractCognitoUser(state).signInUserSession?.accessToken?.payload?.sub
}

export function extractCognitoLanguage(state) {
  const cognitoUser = extractCognitoUser(state)
  return extractCognitoUserLanguage(cognitoUser)
}

export function extractEmailMissing(state) {
  const cognitoUser = extractCognitoUser(state)
  return extractCognitoUserEmailMissing(cognitoUser)
}

export function formatUserFieldsForCreation(state, countryCode) {
  const cognitoUser = extractCognitoUser(state)
  const attributes = cognitoUser.attributes
  const language = attributes[BW_AUTH_LANGUAGE_ATTR] || i18n.language
  return {
    user: {
      auth_id: cognitoUser.signInUserSession.accessToken.payload.sub,
      first_name: attributes[AUTH_FIRST_NAME_ATTR],
      second_name: attributes[AUTH_LAST_NAME_ATTR],
      email: attributes.email,
      brand_id: APP_CONFIG.BRAND_ID,
      language,
      ...(brandConfig.features.newsletterCheckbox && {
        metadata: {
          opts_newsletter: extractSignupProfile(state).optionalCheckbox
        }
      }),
      ...(countryCode && { country_ISO: getCountryISO3(countryCode) })
    }
  }
}

export function extractUserId(state) {
  const { accountReducer } = state.value
  const { userId } = accountReducer.accountProfile
  return userId
}

export function extractCognitoUserSocialIdentity(state) {
  return extractProviderType(state.cognitoUser)
}

export function extractRewardCode(state) {
  const { rewardsReducer } = state.value
  const { rewardCode } = rewardsReducer.rewardsProfile
  return rewardCode
}

export function extractCognitoUserTermsAccepted(cognitoUser) {
  if (Object.keys(cognitoUser).length === 0) {
    return false
  }
  const attributes = extractCognitoUserAttributes(cognitoUser)
  const cognitoTerms = attributes && attributes[AUTH_TERMS_ACCEPTED_ATTR]
  return cognitoTerms && stringToBool(cognitoTerms)
}

export function extractCognitoUserLanguage(cognitoUser) {
  if (Object.keys(cognitoUser).length === 0) {
    return ''
  }
  const attributes = extractCognitoUserAttributes(cognitoUser)
  return attributes[BW_AUTH_LANGUAGE_ATTR]
}

export function extractCognitoUserEmailMissing(cognitoUser) {
  return Boolean(!extractCognitoUserAttributes(cognitoUser)?.email) || false
}

export function extractTokenId(state) {
  const { mainReducer } = state.value
  const { id } = mainReducer.tokenProfile
  return id
}

function extractProviderType(cognitoUser) {
  const payload = cognitoUser.signInUserSession.idToken.payload
  const identities = payload.identities
  if (identities && identities.length > 0) {
    return identities[0].providerType
  }
  return ''
}

export const isSignUpVerified = (signupState) => {
  return signupState === SIGNUP_PROCESS_STEP.SIGNUP_VERIFIED
}

export const isResendSignupVerifySuccess = (signupState) => {
  return signupState === SIGNUP_PROCESS_STEP.RESEND_VERIFICATION_SUCCESS
}

export const isResendSignupVerifyFailed = (signupState) => {
  return signupState === SIGNUP_PROCESS_STEP.RESEND_VERIFICATION_FAILED
}

export const wrapUserAccessToken = (callback) => {
  return authService.getUserSession$().pipe(
    mergeMap((userSession) => {
      return callback(extractAccessToken(userSession))
    })
  )
}
