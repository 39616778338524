import React, { memo } from 'react'
import { faCoin, faCoins } from '@fortawesome/pro-light-svg-icons'
import ReceivePoints from '../common/ReceivePoints'

const AchievedMilestone = ({
  t,
  title,
  desc,
  symbol,
  value,
  goToExchange,
  onCancel,
  ...restProps
}) => (
  <ReceivePoints
    title={title}
    description={desc}
    onCancel={goToExchange && onCancel}
    cancelLabel={goToExchange && 'reward-box.back-to-reward-boxes'}
    submitLabel={
      goToExchange
        ? t('reward-box.go-to-exchange', { symbol })
        : t('reward-box.back-to-reward-boxes')
    }
    t={t}
    icon={goToExchange ? faCoins : faCoin}
    value={value}
    name={value && symbol}
    {...restProps}
  ></ReceivePoints>
)

export default memo(AchievedMilestone)
