import styled from '@emotion/styled'
import React, { memo } from 'react'

import {
  BWPanelContent,
  BWText,
  CSS_FLEX,
  CSS_FULL_WIDTH
} from 'src/libs/qb-brand-web-components'

const ProgressBar = ({
  info,
  completed,
  leftLabel,
  rightLabel,
  height = 18,
  onClick
}) => {
  return (
    <BWPanelContent
      css={{ gap: 5, cursor: onClick && 'pointer' }}
      onClick={onClick}
    >
      {info}
      <ProgressLabel>
        {leftLabel && <BWText label={leftLabel} size={10} />}
        {rightLabel && <BWText label={rightLabel} size={10} />}
      </ProgressLabel>
      <ProgressContainer>
        <ProgressBackground css={{ width: `${completed}%`, height }} />
      </ProgressContainer>
    </BWPanelContent>
  )
}

export default memo(ProgressBar)

const ProgressLabel = styled.div`
  ${CSS_FLEX};
  ${CSS_FULL_WIDTH};
  justify-content: space-between;
  ${({ theme: { colors } }) => `color: ${colors.lightWhite}`};
`

const ProgressContainer = styled.div`
  ${CSS_FULL_WIDTH};
  border-radius: 10px;
  padding: 2px;
  background-color: ${({ theme: { colors } }) => colors.liteWhite};
`

const ProgressBackground = styled.div`
  text-align: right;
  font-size: 10px;
  ${CSS_FLEX};
  align-items: center;
  justify-content: end;
  border-radius: 10px;
  background-color: ${({ theme: { colors } }) => colors.accent};
`
