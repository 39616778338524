import React, { memo } from 'react'
import IcoMoon from 'react-icomoon'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from '@emotion/styled'

import iconSet from '../../icons/selection.json'
import { isString } from '../../util/string.helpers'

const BWIcon = ({
  faIconCss,
  moonIconCss,
  icon,
  size,
  iconColor,
  disabled,
  ...restProps
}) => {
  const renderIcon = () => {
    if (isString(icon)) {
      return (
        <MoonIcon
          iconSet={iconSet}
          icon={icon}
          size={size}
          css={moonIconCss}
          iconColor={iconColor}
          disabled={disabled}
        />
      )
    } else {
      return (
        <FaIcon
          icon={icon}
          iconColor={iconColor}
          disabled={disabled}
          css={faIconCss}
          {...restProps}
        />
      )
    }
  }

  return renderIcon()
}

export default memo(BWIcon)

const iconStyles = ({ theme: { colors }, iconColor, disabled }) => `
  color: ${disabled ? colors.liteWhite : colors[iconColor]};
`

const FaIcon = styled(FontAwesomeIcon, {
  shouldForwardProp: (prop) => prop !== 'iconColor'
})(iconStyles)

const MoonIcon = styled(IcoMoon, {
  shouldForwardProp: (prop) => prop !== 'iconColor'
})(iconStyles)
