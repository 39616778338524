import {
  requiredRule,
  isValidPositiveNumber,
  isValidRewardCode,
  emailRule
} from 'src/libs/qb-brand-web-components'

import { ERROR_TEXT } from '../constants/messages'
import { CUSTOM_BRAND_ID } from '../constants/pointsExchange'
import {
  isValidEtihadCardNumber,
  isValidMileAndMoreNumber
} from './ffAccountValidation.helpers'
import customConfig from 'customConfig'
import { MIN_BRAND_ACCOUNT_NUMBER_LENGTH } from '../constants/app'
import { isExchangeProviderEmailType } from './app/app.helpers'

const minPointsRule = (number, t, minPoints = 1, tokenSymbol) => {
  return !isValidPositiveNumber(number) || number < minPoints
    ? t(ERROR_TEXT.NEED_AT_LEAST_ONE_POINT, {
        points: minPoints,
        tokenSymbol
      })
    : ''
}

const MMAccountNumberRule = (number) => {
  return !isValidMileAndMoreNumber(number)
    ? ERROR_TEXT.INVALID_ACCOUNT_NUMBER
    : ''
}

const EtihadAccountNumberRule = (number) => {
  return !isValidEtihadCardNumber(number)
    ? ERROR_TEXT.INVALID_ACCOUNT_NUMBER
    : ''
}

const isValidUUID = (number) => {
  const valid =
    /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi
  return valid.test(number)
}

const UUIDRule = (accountNumber) => {
  return !isValidUUID(accountNumber.split(/-(.+)/)[1])
    ? ERROR_TEXT.INVALID_ACCOUNT_NUMBER
    : ''
}

const isValidSocialUsername = (number, min = 5, max = 32) => {
  const valid = new RegExp(`^@[a-zA-Z0-9_]{${min},${max}}$`)
  return valid.test(number)
}

const telegramUsernameRule = (accountNumber) => {
  return !isValidSocialUsername(accountNumber) ? 'error.invalid-username' : ''
}

const xUsernameRule = (accountNumber) => {
  return !isValidSocialUsername(accountNumber, 4, 15)
    ? 'error.invalid-username'
    : ''
}

const qbxTapUsernameRule = (accountNumber) => {
  const valid = /^[0-9]{1,24}$/gi
  return !valid.test(accountNumber) ? 'brand:eligible.invalid-qbx-tap-id' : ''
}

const brandAccountNumberRule = (value) => {
  const valueLength = value.length
  return valueLength < MIN_BRAND_ACCOUNT_NUMBER_LENGTH
    ? ERROR_TEXT.INVALID_ACCOUNT_NUMBER
    : ''
}

const validationRulesForAccountNumberSanity = (brandId) => {
  const rules = [requiredRule]
  if (brandId === CUSTOM_BRAND_ID.MILES_AND_MORE) {
    rules.push(MMAccountNumberRule)
  } else if (brandId === CUSTOM_BRAND_ID.ETIHAD) {
    rules.push(EtihadAccountNumberRule)
  } else if (brandId === CUSTOM_BRAND_ID.TELEGRAM) {
    rules.push(telegramUsernameRule)
  } else if (brandId === CUSTOM_BRAND_ID.X) {
    rules.push(xUsernameRule)
  } else if (brandId === CUSTOM_BRAND_ID.QBX_TAP) {
    rules.push(qbxTapUsernameRule)
  } else if (isExchangeProviderEmailType(brandId)) {
    rules.push(emailRule)
  } else {
    rules.push(brandAccountNumberRule)
  }
  return rules
}

const validRewardCodeRule = (rewardCode) => {
  const { min, max } = customConfig.rewardCodeRule
  return isValidRewardCode(rewardCode, min, max)
}

const moreThanZeroRule = (rate, value) => {
  return isEqualToZero(Math.floor(value * rate))
    ? ERROR_TEXT.NOT_SUFFICIENT_AMOUNT
    : ''
}

const isEqualToZero = (value) => {
  return value <= 0
}

export {
  minPointsRule,
  validationRulesForAccountNumberSanity,
  UUIDRule,
  validRewardCodeRule,
  EtihadAccountNumberRule,
  MMAccountNumberRule,
  moreThanZeroRule,
  brandAccountNumberRule
}
