import React, { memo } from 'react'
import {
  BWText,
  CSS_FLEX_ITEMS_CENTER,
  cssCustomGap,
  BWIcon
} from 'src/libs/qb-brand-web-components'
import styled from '@emotion/styled'

const StatusBadge = ({
  t,
  status = 'common.status',
  label,
  icon,
  iconColor,
  statusWeight = 500,
  labelWeight = 700,
  customCss
}) => {
  return (
    <StatusBadgeContainer css={customCss}>
      <BWText label={`${t(status)}: `} alignCenter weight={statusWeight} />
      <BWText label={t(label)} weight={labelWeight} alignCenter />
      <BWIcon icon={icon} iconColor={iconColor} />
    </StatusBadgeContainer>
  )
}

export default memo(StatusBadge)

const StatusBadgeContainer = styled.div`
  ${CSS_FLEX_ITEMS_CENTER};
  ${cssCustomGap(5)};
  margin-top: 5px;
`
