import { mergeToNewObject } from 'src/libs/qb-brand-web-components'

import { cashbackParams } from '../utils/link.helpers'
import customConfig from 'customConfig'

const overviewCashbackLink = (t) => {
  return mergeToNewObject(cashbackParams(), {
    label: t(customConfig.getCashbackLabel)
  })
}

export default overviewCashbackLink
