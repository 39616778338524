import React, { memo } from 'react'
import { Trans } from 'react-i18next'

import CardWithBody from '../shared/panel/CardWithBody'
import Button from '../shared/button/Button'
import {
  REWARD_BOX_PROCESS_STATUS,
  REWARD_BOX_WITHDRAW_MAX_LIMIT
} from '../../constants/rewardBox'
import ProgressBar from '../shared/progressBar/ProgressBar'
import {
  BW_COLOR_ERROR,
  BWPanelContent,
  BWText,
  formatCurrencyValue
} from 'src/libs/qb-brand-web-components'
import { percentageOfCompletion } from '../../util/number.helpers'

const WithdrawMigration = ({
  t,
  onWithdraw,
  rewardBoxStatus,
  rewardBoxErr,
  rewardBoxMsg,
  earnedReward
}) => {
  const refundErr = rewardBoxErr.refund
  const isRefunding =
    rewardBoxStatus === REWARD_BOX_PROCESS_STATUS.REFUNDING && !refundErr
  return (
    <CardWithBody
      title='Migrating to a newer version'
      desc="Withdraw your ETH rewards and you'll then be able to play on the new version."
      body={
        <>
          <ProgressBar
            leftLabel='$0'
            rightLabel={`$${REWARD_BOX_WITHDRAW_MAX_LIMIT}`}
            completed={percentageOfCompletion(
              earnedReward,
              REWARD_BOX_WITHDRAW_MAX_LIMIT
            )}
            info={
              <BWText
                label={
                  <Trans
                    i18nKey='reward-box.current-pool-value'
                    values={{
                      value: formatCurrencyValue(earnedReward, 3) || 0
                    }}
                  />
                }
              />
            }
          />
          <BWPanelContent>
            <Button
              label={t('reward-box.withdraw-your-rewards')}
              onClick={onWithdraw}
              disabled={isRefunding || earnedReward <= 0}
            />
            {refundErr && (
              <BWText
                color={BW_COLOR_ERROR}
                label={t(refundErr)}
                size={14}
                weight={500}
                alignCenter
              />
            )}
            {rewardBoxMsg.refund && (
              <BWText
                label={rewardBoxMsg.refund}
                size={14}
                weight={500}
                alignCenter
              />
            )}
          </BWPanelContent>
        </>
      }
    />
  )
}

export default memo(WithdrawMigration)
