import React, { memo } from 'react'
import { useNavigate } from 'react-router-dom'

import BackButton from './BackButton'

const HomeLinkButton = ({ t }) => {
  const navigate = useNavigate()
  return (
    <BackButton
      t={t}
      onClick={() => navigate(-1)}
      styles={{ color: 'inherit', textDecoration: 'inherit' }}
    />
  )
}

export default memo(HomeLinkButton)
