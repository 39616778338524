import React, { memo } from 'react'
import {
  BWPanelHeader,
  openExternalUrl
} from 'src/libs/qb-brand-web-components'

import brandConfig from 'brandConfig'
import OverviewEnterCode from './OverviewEnterCode'
import { exchangeProviderLabel } from '../../util/pointsExchange.helpers'
import Button from '../shared/button/Button'
import OverviewHowToEarnWrapper from 'wrappers/OverviewHowToEarnWrapper'
import BalanceWithAnimation from '../shared/overview/BalanceWithAnimation'
import OverviewMainPanelContent from './OverviewMainPanelContent'

const OverviewMainPanel = ({
  onEnterRewardCodeClick,
  currentUserPoints,
  previousUserPoints,
  tokenProfile: { name, symbol },
  t,
  onClickHowToEarn,
  icon,
  ...restProps
}) => {
  return (
    <OverviewMainPanelContent t={t} {...restProps}>
      <BWPanelHeader
        title={
          <BalanceWithAnimation
            previousUserPoints={previousUserPoints}
            currentUserPoints={currentUserPoints}
          />
        }
        description={exchangeProviderLabel({ name, symbol })}
        icon={icon}
        color={brandConfig.prefIconColor}
      />
      <OverviewEnterCode
        t={t}
        onEnterRewardCodeClick={onEnterRewardCodeClick}
        howToEarnLink={
          !brandConfig.features.disableHowToEarnRewards && (
            <OverviewHowToEarnWrapper t={t} onClick={onClickHowToEarn} />
          )
        }
        howDoesItWorkButton={
          brandConfig.redirectUrl && (
            <Button
              label={t('brand:reward.how-to-earn-rewards')}
              onClick={() => openExternalUrl(brandConfig.redirectUrl)}
            />
          )
        }
      />
    </OverviewMainPanelContent>
  )
}

export default memo(OverviewMainPanel)
