import { getDefaultConfig } from '@rainbow-me/rainbowkit'
import { base, sepolia } from 'wagmi/chains'
import { http } from 'wagmi'

import { abi } from '../../../abis/abi'
import { parseJson } from '../../../util/app/app.helpers'
import { isProduction } from '../../../libs/qb-brand-web-components'

const isEnvProd = isProduction() || true
const chains = [base, sepolia]
const transports = isEnvProd
  ? {
      [base.id]: http()
    }
  : {
      [sepolia.id]: http()
    }

const getWagmiConfig = () => {
  return getDefaultConfig({
    appName: `qbx${!isEnvProd ? '-testing' : ''}`,
    projectId: process.env.REACT_APP_WALLET_PROJECT_ID,
    chains,
    transports,
    batch: { multicall: true },
    ssr: false
  })
}

const getChain = () => {
  return isEnvProd ? base : sepolia
}

const address = parseJson(process.env.REACT_APP_CONTRACT_ADDRESS)

const wagmiContractConfig = {
  address: address.new,
  abi
}

const wagmiContractConfigOld = {
  address: address.old,
  abi
}

export { getWagmiConfig, getChain, wagmiContractConfig, wagmiContractConfigOld }
