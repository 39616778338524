import React, { memo } from 'react'
import { BWText, BWPanelContent } from 'src/libs/qb-brand-web-components'

import Panel from './Panel'

const CardWithBody = ({ title, desc, body, subHeading, ...restProps }) => {
  return (
    <Panel {...restProps}>
      <BWPanelContent>
        <BWText label={title} size={18} weight={700} alignCenter />
        {desc && <BWText label={desc} alignCenter />}
        {subHeading}
      </BWPanelContent>
      {body}
    </Panel>
  )
}

export default memo(CardWithBody)
