import { useState, memo, useRef, useEffect } from 'react'
import styled from '@emotion/styled'

const TooltipContainer = styled.div`
  position: relative;
  display: inline-block;
`

const TooltipText = styled.div`
  visibility: ${({ isVisible }) => (isVisible ? 'visible' : 'hidden')};
  width: 250px;
  font-size: 12px;
  background-color: ${({ theme }) => theme.colors.zambezi};
  color: ${({ theme }) => theme.colors.liteWhite};
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 10;
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  transition:
    opacity 0.3s ease,
    visibility 0.3s ease;
  ${({ position }) => positionStyles[position]}
`

const arrowStyles = `
  content: '';
  position: absolute;
  border-width: 5px;
  border-style: solid;
`

const positionStyles = {
  top: `
    bottom: 125%;
    left: 50%;
    transform: translateX(-50%);
    &::after {
      ${arrowStyles}
      top: 100%;
      left: 50%;
      transform: translateX(-50%);
      border-color: #5f5f5f transparent transparent transparent;
    }
  `,
  bottom: `
    top: 125%;
    left: 50%;
    transform: translateX(-50%);
    &::after {
      ${arrowStyles}
      bottom: 100%;
      left: 50%;
      transform: translateX(-50%);
      border-color: transparent transparent #5f5f5f transparent;
    }
  `,
  left: `
    top: 50%;
    right: 125%;
    transform: translateY(-50%);
    &::after {
      ${arrowStyles}
      top: 50%;
      left: 100%;
      transform: translateY(-50%);
      border-color: transparent transparent transparent #5f5f5f;
    }
  `,
  right: `
    top: 50%;
    left: 125%;
    transform: translateY(-50%);
    &::after {
      ${arrowStyles}
      top: 50%;
      right: 100%;
      transform: translateY(-50%);
      border-color: transparent #5f5f5f transparent transparent;
    }
  `
}

const Tooltip = ({ text, children }) => {
  const [isTooltipVisible, setIsTooltipVisible] = useState(false)
  const [position, setPosition] = useState('bottom')
  const tooltipRef = useRef(null)
  const containerRef = useRef(null)

  const toggleTooltipVisibility = (visible) => setIsTooltipVisible(visible)

  useEffect(() => {
    if (!isTooltipVisible) return

    const tooltipEl = tooltipRef.current
    const containerEl = containerRef.current
    const containerRect = containerEl.getBoundingClientRect()
    const tooltipRect = tooltipEl.getBoundingClientRect()

    // Calculate available space around the element
    const spaceAbove = containerRect.top
    const spaceBelow = window.innerHeight - containerRect.bottom
    const spaceLeft = containerRect.left
    const spaceRight = window.innerWidth - containerRect.right

    // Determine best position
    let newPosition = 'bottom'
    if (spaceBelow < tooltipRect.height && spaceAbove > tooltipRect.height) {
      newPosition = 'top'
    } else if (
      spaceRight < tooltipRect.width &&
      spaceLeft > tooltipRect.width
    ) {
      newPosition = 'left'
    } else if (
      spaceLeft < tooltipRect.width &&
      spaceRight > tooltipRect.width
    ) {
      newPosition = 'right'
    }
    setPosition(newPosition)
  }, [isTooltipVisible])

  return (
    <TooltipContainer
      onMouseEnter={() => toggleTooltipVisibility(true)}
      onMouseLeave={() => toggleTooltipVisibility(false)}
      ref={containerRef}
    >
      {children}
      <TooltipText
        ref={tooltipRef}
        isVisible={isTooltipVisible}
        position={position}
      >
        {text}
      </TooltipText>
    </TooltipContainer>
  )
}

export default memo(Tooltip)
