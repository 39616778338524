import React from 'react'
import ReactDOM from 'react-dom/client'

import './index.css'
import { getApp } from './App'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'

const root = ReactDOM.createRoot(document.getElementById('root'))

getApp().then((App) => {
  root.render(<App />)
})

// serviceWorkerRegistration.register({
//   onUpdate: (registration) => {
//     if (registration.waiting) {
//       registration.waiting.postMessage({ type: 'SKIP_WAITING' })
//       window.location.reload()
//     }
//   }
// })

serviceWorkerRegistration.unregister()
