import React, { memo } from 'react'
import {
  BWNoRecordsFound,
  CSS_GRID_TWO_COLUMNS,
  BWPageContent
} from 'src/libs/qb-brand-web-components'

import RedeemHeadingWrapper from 'wrappers/RedeemHeadingWrapper'
import RedeemOptionWrapper from 'wrappers/RedeemOptionWrapper'
import customConfig from 'customConfig'
import brandConfig from 'brandConfig'
import StoreFrontWrapper from 'wrappers/StoreFrontWrapper'

const Redeem = ({
  redeemOptions,
  currentUserPoints,
  onGiftCardSelect,
  tokenProfile: { symbol },
  t
}) => {
  const isDataLengthOdd = redeemOptions?.length % 2 !== 0
  return (
    <>
      <RedeemHeadingWrapper t={t} />
      {brandConfig.features.enableCOEStorefrontRedeem && (
        <StoreFrontWrapper t={t} customCss={{ marginTop: 10 }} />
      )}
      {redeemOptions?.length <= 0 ? (
        <BWNoRecordsFound
          icon={customConfig.giftCardIcon}
          description={t(customConfig.noRedemptionOptionLabel)}
        />
      ) : (
        <BWPageContent css={CSS_GRID_TWO_COLUMNS}>
          {redeemOptions?.map((redeemOption, index) => (
            <RedeemOptionWrapper
              currentUserPoints={currentUserPoints}
              fullColumn={isDataLengthOdd && index === 0}
              key={index}
              redeemOption={redeemOption}
              tokenSymbol={symbol}
              onClick={() => onGiftCardSelect(redeemOption)}
              t={t}
            />
          ))}
        </BWPageContent>
      )}
    </>
  )
}

export default memo(Redeem)
