import React, { memo } from 'react'
import {
  BWIcon,
  BWText,
  CSS_CURSOR_POINTER
} from 'src/libs/qb-brand-web-components'
import styled from '@emotion/styled'
import { faAngleLeft } from '@fortawesome/pro-solid-svg-icons'

import customConfig from 'customConfig'

const BackButton = ({ t, ...restProps }) => {
  return (
    <ButtonContainer {...restProps}>
      <BWIcon icon={faAngleLeft} />
      <BWText
        label={t(customConfig.goBackLabel)}
        weight={700}
        customCss={{ marginLeft: 10 }}
      />
    </ButtonContainer>
  )
}

export default memo(BackButton)

const ButtonContainer = styled.div`
  ${CSS_CURSOR_POINTER};
  padding: 8px;
  width: fit-content;
  ${({ styles }) => styles}
`
