import React, { useEffect, useMemo, useRef, useState } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Trans, useTranslation } from 'react-i18next'
import {
  useAccount,
  useReadContracts,
  useSwitchChain,
  useWaitForTransactionReceipt,
  useWriteContract
} from 'wagmi'
import { formatEther, parseEther } from 'viem'
import { useNavigate } from 'react-router-dom'

import RewardBoxes from '../components/rewardBoxes/RewardBoxes'
import { REWARD_BOX_PROCESS_STATUS } from '../constants/rewardBox'
import {
  buildCommunityMilestoneDetails,
  buildPopupDetails,
  buildTransactionErrorMessage,
  findNearestMilestone,
  getEthPriceForRewardBox,
  isWalletAccountConnected
} from '../util/rewardBox.helpers'
import {
  getChain,
  wagmiContractConfig,
  wagmiContractConfigOld
} from '../brands/qbx_vip/utils/wagmiConfig'
import { EXCHANGE_POINTS_URL, HOME_URL } from '../constants/navigation'
import {
  formatCurrencyValue,
  usePreviousState
} from 'src/libs/qb-brand-web-components'
import {
  getCryptoExchangeRates,
  stopGetCryptoExchangeRates
} from '../store/actions/exchange'
import {
  changeMilestoneState,
  increaseMilestone,
  startJackpotWinnerPolling,
  stopJackpotWinnerPolling
} from '../store/actions/rewardBox'
import {
  DEFAULT_CONTAINER_STATE,
  REWARD_BOX_PROCESS_STEP
} from '../constants/containerStates'
import AchievedMilestone from '../components/popups/rewardBox/AchievedMilestone'
import { getLocalStorage, setLocalStorage } from '../util/local.helpers'
import { MILESTONE_PROFILE_DISPATCH } from '../store/reducers/rewardBox/milestone'

const RewardBoxesContainer = ({
  tokenProfile,
  getCryptoExchangeRates,
  stopGetCryptoExchangeRates,
  cryptoExchanges,
  increaseMilestone,
  startJackpotWinnerPolling,
  stopJackpotWinnerPolling,
  milestoneProfile,
  changeMilestoneState,
  ...restProps
}) => {
  const scrollRef = useRef(null)

  const { t } = useTranslation()

  const { address, status, isConnecting, isReconnecting, chainId } =
    useAccount()

  const navigate = useNavigate()
  useEffect(() => {
    handleNoPopup()
    return () => {
      handleNoPopup()
    }
  }, [])

  useEffect(() => {
    if (!isWalletAccountConnected({ status, address })) {
      if (!(isConnecting || isReconnecting)) {
        navigate(HOME_URL, { replace: true })
      }
    }
  }, [status, address, isConnecting, isReconnecting])

  useEffect(() => {
    const mileStoneReached = findNearestMilestone(
      milestoneProfile.data.individual,
      milestoneProfile.achievedIndividual
    )
    if (mileStoneReached && milestoneProfile.showPopup) {
      handleShowPopup(
        buildPopupDetails(t, mileStoneReached, tokenProfile.symbol)
      )
    }
  }, [milestoneProfile])

  useEffect(() => {
    let community = milestoneProfile.data.community?.[0]
    const jackpotWinner = milestoneProfile.jackpotWinner
    if (community && jackpotWinner) {
      const alreadyShown = getLocalStorage(jackpotWinner)
      if (!alreadyShown) {
        setLocalStorage(jackpotWinner, true)
        handleShowPopup(buildCommunityMilestoneDetails(t, community))
      }
    }
  }, [milestoneProfile.jackpotWinner])

  const [rewardBoxStatus, setRewardBoxStatus] = useState(
    REWARD_BOX_PROCESS_STATUS.INIT
  )

  const [rewardBoxErr, setRewardBoxErr] = useState({})
  const [rewardBoxMsg, setRewardBoxMsg] = useState({})
  // const [earnedRewardOld, setEarnedRewardOld] = useState(0)
  // const [earnedReward, setEarnedReward] = useState(0)
  const [isBlacklisted, setIsBlacklisted] = useState(false)
  const [onOpenRewardBox, setOnOpenRewardBox] = useState(false)
  const [popupState, setPopupState] = useState({
    id: DEFAULT_CONTAINER_STATE.NONE,
    data: null
  })

  // const [amountReceived, setAmountReceived] = useState({
  //   isETH: true,
  //   value: 0
  // })

  useEffect(() => {
    if (address) {
      // getCryptoExchangeRates()
      startJackpotWinnerPolling()
    }
    return () => {
      // stopGetCryptoExchangeRates()
      stopJackpotWinnerPolling()
    }
  }, [address])

  const {
    data: addFundsHash,
    isPending: addFundsPending,
    error: addFundsErr,
    writeContract: addFundsToContract
  } = useWriteContract()

  const {
    isLoading: isDepositProcessing,
    error: depositFundsErr,
    isSuccess: isDepositConfirmed
  } = useWaitForTransactionReceipt({
    hash: addFundsHash
  })

  useEffect(() => {
    if (addFundsPending || isDepositProcessing) {
      setRewardBoxErr({})
      setRewardBoxStatus(REWARD_BOX_PROCESS_STATUS.DEPOSITING)
    }
  }, [addFundsPending, isDepositProcessing])

  const {
    switchChain,
    isSuccess: networkSwitchSuccess,
    error: networkSwitchErr
  } = useSwitchChain()

  useEffect(() => {
    if (addFundsErr || depositFundsErr || networkSwitchErr) {
      setRewardBoxErr({
        deposit: buildTransactionErrorMessage(
          addFundsErr?.shortMessage ||
            depositFundsErr?.shortMessage ||
            networkSwitchErr?.shortMessage
        )
      })
      setRewardBoxMsg({})
    }
  }, [addFundsErr, depositFundsErr, networkSwitchErr])

  // const prevEarnedReward = usePreviousState(earnedReward)

  useEffect(() => {
    if (isDepositConfirmed) {
      increaseMilestone()
    }
  }, [isDepositConfirmed])

  useEffect(() => {
    if (
      isDepositConfirmed
      // && earnedReward
    ) {
      // setAmountReceived({
      //   ...amountReceived,
      //   value: earnedReward - prevEarnedReward
      // })
      setRewardBoxStatus(REWARD_BOX_PROCESS_STATUS.DEPOSITED)
    }
  }, [
    isDepositConfirmed
    // , earnedReward
  ])

  const isWalletConnected = isWalletAccountConnected({ status, address })

  const contractResponse = useReadContracts({
    contracts: [
      ...(isWalletConnected
        ? [
            {
              ...wagmiContractConfig,
              functionName: 'rewards',
              args: [address]
            },
            {
              ...wagmiContractConfig,
              functionName: 'blacklist',
              args: [address]
            }
          ]
        : [])
    ]
  })

  useEffect(() => {
    if (contractResponse?.data?.length) {
      // const [userDepositData, blacklistData] = contractResponse.data
      const blacklistData = contractResponse.data[1]
      // setEarnedReward(
      //   (userDepositData?.result
      //     ? parseFloat(formatEther(userDepositData?.result))
      //     : 0) * ethPrice
      // )
      if (blacklistData?.result) {
        setIsBlacklisted(true)
      }
    }
  }, [contractResponse.data])

  useEffect(() => {
    if (networkSwitchSuccess && onOpenRewardBox) {
      executeDeposit()
    }
  }, [networkSwitchSuccess, onOpenRewardBox])

  const handleOpenAnotherBox = () => {
    setRewardBoxStatus(REWARD_BOX_PROCESS_STATUS.INIT)
  }

  const handleOpenRewardBox = () => {
    const configChainId = getChain().id
    setOnOpenRewardBox(true)
    if (chainId && configChainId && chainId !== configChainId) {
      switchChain({ chainId: configChainId })
    } else {
      executeDeposit()
    }
  }

  const executeDeposit = () => {
    setRewardBoxMsg({})
    addFundsToContract({
      ...wagmiContractConfig,
      functionName: 'accrue',
      args: [],
      value: parseEther('')
    })
    setRewardBoxStatus(REWARD_BOX_PROCESS_STATUS.DEPOSITING)
  }

  const onRefreshContractResponse = () => {
    contractResponse.refetch()
  }

  const {
    data: refundFundsHash,
    isPending: refundFundsPending,
    error: refundFundsErr,
    writeContract: refundFundsFromContract
  } = useWriteContract()

  const { isLoading: isRefundProcessing, isSuccess: isRefundConfirmed } =
    useWaitForTransactionReceipt({
      hash: refundFundsHash
    })

  useEffect(() => {
    if (refundFundsPending || isRefundProcessing) {
      setRewardBoxErr({})
      setRewardBoxStatus(REWARD_BOX_PROCESS_STATUS.REFUNDING)
      setRewardBoxMsg({
        refund: t('reward-box.transaction-processing')
      })
    }
  }, [refundFundsPending, isRefundProcessing])

  useEffect(() => {
    if (refundFundsErr) {
      setRewardBoxErr({
        refund: buildTransactionErrorMessage(refundFundsErr?.shortMessage)
      })
      setRewardBoxMsg({})
    }
  }, [refundFundsErr])

  // useEffect(() => {
  //   if (isRefundConfirmed) {
  //     setRewardBoxMsg({
  //       refund: (
  //         <Trans
  //           i18nKey='reward-box.refunded-info'
  //           values={{ value: formatCurrencyValue(prevEarnedReward, 2) }}
  //         />
  //       )
  //     })
  //   }
  // }, [isRefundConfirmed])

  useEffect(() => {
    if (isDepositConfirmed || isRefundConfirmed) {
      setRewardBoxErr({})
      onRefreshContractResponse()
    }
  }, [isDepositConfirmed, isRefundConfirmed])

  const contractResponseOld = useReadContracts({
    contracts: [
      ...(isWalletConnected
        ? [
            {
              ...wagmiContractConfigOld,
              functionName: 'rewards',
              args: [address]
            }
          ]
        : [])
    ]
  })

  useEffect(() => {
    if (contractResponseOld?.data?.length) {
      const [userDepositData] = contractResponseOld.data
      const amount = parseFloat(formatEther(userDepositData?.result))
      if (address && amount > 0) {
        getCryptoExchangeRates()
      }
    }
    return () => {
      stopGetCryptoExchangeRates()
    }
  }, [address, contractResponseOld.data])

  const earnedRewardOld = useMemo(() => {
    if (contractResponseOld?.data?.length) {
      const ethPrice = getEthPriceForRewardBox(cryptoExchanges)
      const [userDepositData] = contractResponseOld.data
      return (
        (userDepositData?.result
          ? parseFloat(formatEther(userDepositData?.result))
          : 0) * ethPrice
      )
    }
  }, [cryptoExchanges, contractResponseOld.data])

  // useEffect(() => {
  //   if (contractResponseOld?.data?.length) {
  //     const [userDepositData] = contractResponseOld.data
  //     setEarnedRewardOld(
  //       (userDepositData?.result
  //         ? parseFloat(formatEther(userDepositData?.result))
  //         : 0) * ethPrice
  //     )
  //   }
  // }, [contractResponseOld.data])

  // @TODO: remove later
  const prevEarnedRewardOld = usePreviousState(earnedRewardOld)

  const {
    data: refundFundsHashOld,
    isPending: refundFundsPendingOld,
    error: refundFundsErrOld,
    writeContract: refundFundsFromContractOld
  } = useWriteContract()

  const { isLoading: isRefundProcessingOld, isSuccess: isRefundConfirmedOld } =
    useWaitForTransactionReceipt({
      hash: refundFundsHashOld
    })

  useEffect(() => {
    if (refundFundsPendingOld || isRefundProcessingOld) {
      setRewardBoxErr({})
      setRewardBoxStatus(REWARD_BOX_PROCESS_STATUS.REFUNDING)
      setRewardBoxMsg({
        refund: t('reward-box.transaction-processing')
      })
    }
  }, [refundFundsPendingOld, isRefundProcessingOld])

  useEffect(() => {
    if (refundFundsErrOld) {
      setRewardBoxErr({
        refund: buildTransactionErrorMessage(refundFundsErrOld?.shortMessage)
      })
      setRewardBoxMsg({})
    }
  }, [refundFundsErrOld])

  const onRefreshContractResponseOld = () => {
    contractResponseOld.refetch()
  }

  useEffect(() => {
    if (isRefundConfirmedOld) {
      setRewardBoxErr({})
      setRewardBoxMsg({
        refund: (
          <Trans
            i18nKey='reward-box.refunded-info'
            values={{ value: formatCurrencyValue(prevEarnedRewardOld, 2) }}
          />
        )
      })
      onRefreshContractResponseOld()
    }
  }, [isRefundConfirmedOld])

  const handleClickRefund = () => {
    setRewardBoxMsg({})
    refundFundsFromContract({
      ...wagmiContractConfig,
      functionName: 'withdraw',
      args: []
    })
  }

  // @TODO: remove later
  const handleClickRefundOld = () => {
    setRewardBoxMsg({})
    refundFundsFromContractOld({
      ...wagmiContractConfigOld,
      functionName: 'withdraw',
      args: []
    })
  }

  const handleRewardTimer = () => {
    setRewardBoxStatus(REWARD_BOX_PROCESS_STATUS.INIT)
  }

  const handleScrollToWithdraw = () => {
    scrollRef.current?.scrollIntoView({ behavior: 'smooth' })
  }

  const handleShowPopup = (data) => {
    changeMilestoneState(MILESTONE_PROFILE_DISPATCH.SHOW_POPUP, false)
    setPopupState({ id: REWARD_BOX_PROCESS_STEP.ACHIEVED_MILESTONE, data })
  }

  const handleNoPopup = () => {
    setPopupState({ data: null, id: DEFAULT_CONTAINER_STATE.NONE })
  }

  const handlePopupSubmit = () => {
    switch (popupState.id) {
      case REWARD_BOX_PROCESS_STEP.ACHIEVED_MILESTONE:
        const goToExchange = popupState?.data?.goToExchange
        handleNoPopup()
        if (goToExchange) {
          navigate(EXCHANGE_POINTS_URL)
        }
        break
      default:
        handleNoPopup()
        break
    }
  }

  const renderPopup = () => {
    switch (popupState.id) {
      case REWARD_BOX_PROCESS_STEP.ACHIEVED_MILESTONE:
        return (
          <AchievedMilestone
            onSubmit={handlePopupSubmit}
            onCancel={handleNoPopup}
            t={t}
            symbol={tokenProfile.symbol}
            {...popupState.data}
          />
        )

      default:
        break
    }
  }

  return (
    <>
      <RewardBoxes
        t={t}
        tokenProfile={tokenProfile}
        openRewardProps={{
          onOpenAnotherBox: handleOpenAnotherBox,
          onOpenRewardBox: handleOpenRewardBox,
          // amountReceived,
          endedRewardTimer: handleRewardTimer,
          isBlacklisted
        }}
        withdrawProps={{
          onWithdraw: handleClickRefund,
          rewardBoxMsg,
          // earnedReward,
          ref: scrollRef
        }}
        withdrawPropsOld={{
          onWithdraw: handleClickRefundOld,
          rewardBoxMsg,
          earnedReward: earnedRewardOld
        }}
        rewardBoxErr={rewardBoxErr}
        rewardBoxStatus={rewardBoxStatus}
        onScrollToWithdraw={handleScrollToWithdraw}
        onShowPopup={handleShowPopup}
        milestoneProfile={milestoneProfile}
        {...restProps}
      />
      {renderPopup()}
    </>
  )
}

const mapStateToProps = ({
  mainReducer,
  exchangeReducer,
  rewardBoxReducer
}) => {
  const { tokenProfile } = mainReducer
  const { cryptoExchangeProfile } = exchangeReducer
  const { milestoneProfile } = rewardBoxReducer
  return {
    tokenProfile,
    cryptoExchanges: cryptoExchangeProfile.cryptoExchanges,
    milestoneProfile
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getCryptoExchangeRates,
      stopGetCryptoExchangeRates,
      increaseMilestone,
      startJackpotWinnerPolling,
      stopJackpotWinnerPolling,
      changeMilestoneState
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RewardBoxesContainer)
