import { formatCurrencyValue } from '../libs/qb-brand-web-components'
import { formatTicks } from './string.helpers'

const numberCeil = (number) => Math.ceil(number)

const floorNumber = (n) => Math.floor(n)

const formatCurrencyShort = (value) => {
  return formatTicks(formatCurrencyValue(value))
}

const percentageOfCompletion = (current, total) => {
  if (!total) {
    return 0
  }
  const completed = (current / total) * 100
  return Math.round(completed > 100 ? 100 : completed)
}

export { numberCeil, floorNumber, formatCurrencyShort, percentageOfCompletion }
