import React, { useState, memo } from 'react'
import { useTranslation } from 'react-i18next'
import { BWLink, BWText } from 'src/libs/qb-brand-web-components'

import OptionalCheckbox from '../../../components/shared/optionalCheckbox/OptionalCheckbox'
import brandConfig from 'brandConfig'
import OptionalAndMandatoryCheckboxes from '../../../components/shared/optionalAndMandatoryCheckboxes/OptionalAndMandatoryCheckboxes'
import { SIGNUP_PROFILE_DISPATCH } from '../../../store/reducers/auth/signup'

const TermsNotAcceptedWrapper = ({
  signupProfile,
  onCancelTerms,
  onAcceptTermsSubmit,
  dispatchActionSignup
}) => {
  const { t } = useTranslation()
  const { error, mandatoryCheckbox } = signupProfile
  const [showMore, setShowMore] = useState(false)

  const handleOnViewDetails = () => {
    setShowMore(!showMore)
  }

  return (
    <OptionalAndMandatoryCheckboxes
      signupProfile={signupProfile}
      dispatchActionSignup={dispatchActionSignup}
      onAcceptTermsSubmit={onAcceptTermsSubmit}
      onCancelTerms={onCancelTerms}
    >
      {brandConfig.features.enableMandatoryCheckbox && (
        <>
          <OptionalCheckbox
            dispatchAction={dispatchActionSignup}
            error={error}
            checkbox={mandatoryCheckbox}
            description={
              <>
                <BWText label={t('brand:common.opt-for-newsletter')} />
                <BWText
                  label={
                    <BWLink
                      label={t('brand:common.details')}
                      onClick={handleOnViewDetails}
                    />
                  }
                />
              </>
            }
            dispatchCheckbox={SIGNUP_PROFILE_DISPATCH.MANDATORY_CHECKBOX}
          />
          {showMore && (
            <BWText label={t('brand:common.opt-checkbox-details')} />
          )}
        </>
      )}
    </OptionalAndMandatoryCheckboxes>
  )
}

export default memo(TermsNotAcceptedWrapper)
