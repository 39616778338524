export const MESSAGE_TEXT = {
  PASSWORD_CHANGED: 'message.your-password-was-changed',
  FIRST_NAME_CHANGED: 'message.first-name-was-changed',
  LAST_NAME_CHANGED: 'message.last-name-was-changed',
  COUNTRY_CHANGED: 'country-settings.country-was-changed',
  VERIFICATION_EMAIL_SENT: 'message.verification-email-was-sent',
  FORGOT_PASSWORD_SUCCESS: 'message.forgot-password-success',
  SELECT_MILES_PROGRAM_DESCRIPTION: 'exchange.select-ff-program',
  CONNECT_PROGRAM_TO_EXCHANGE: 'exchange.connect-program-to-exchange',
  ENTER_POINTS_TO_EXCHANGE: 'exchange.enter-points-to-exchange',
  RECEIVE_POINTS: 'message.you-will-receive-following-amount-of-points',
  REWARD_CODE_SUCCESS: 'message.reward-code-success',
  NFT_SUCCESS_DESCRIPTION: 'brand:nft.received-nft-success',
  SELECT_PROGRAM_TO_EXCHANGE: 'exchange.select-program-to-exchange'
}

export const ERROR_TEXT = {
  NEED_AT_LEAST_ONE_POINT: 'error.need-at-least-one-point',
  FORGOT_PASSWORD_ERROR: 'error.forgot-password-error',
  EMAIL_ALREADY_EXISTS: 'error.email-already-exists',
  INCORRECT_OLD_PASSWORD: 'error.old-password-incorrect',
  EMAIL_VERIFICATION_FAILED: 'error.email-verification-failed',
  PASSWORD_LINK_EXPIRED: 'error.password-reset-link-expired',
  RECEIVE_REWARD_FAILED: 'error.receive-reward-failed',
  OLD_NEW_PASSWORDS_SAME: 'error.old-new-passwords-are-same',
  NO_ENOUGH_POINTS: 'error.no-enough-points',
  EXCEED_MAX_LIMIT: 'error.exceed-max-limit',
  NOT_SUFFICIENT_AMOUNT: 'error.not-sufficient-amount',
  INVALID_ACCOUNT_NUMBER: 'error.invalid-account-number',
  RECEIVE_MILES_FROM_BRAND_POINTS_FAILED: 'exchange.receive-miles-failed',
  NO_NFTS: 'brand:nft.no-nfts-found',
  RESEND_EMAIL_VERIFICATION_FAILED: 'error.failed-to-resend-verification-email',
  FAILED_TO_VERIFY_CLAIM_REWARD: 'quest.failed-verify-claim-reward'
}

export const LABEL_TEXT = {
  YOUR_NFTS: 'brand:nft.your-nfts',
  MAX: 'max',
  MIN: 'min',
  LOGIN: 'Login',
  SIGNUP: 'Signup',
  REDEEM_POINTS: 'redeem.redeem-points',
  GET_DISCOUNTS: 'redeem.get-discounts',
  GET_MILES: 'exchange.get-miles',
  GET_DISCOUNTS_ON_BRAND: 'redeem.get-discounts-on-brand',
  ENTER_CODE: 'common.enter-code',
  EXCHANGE_YOUR_POINTS: 'exchange.exchange-your-points',
  EXCHANGE_POINTS: 'exchange.exchange-points',
  MILES: 'exchange.miles',
  POINTS: 'common.points',
  CRYPTO: 'exchange.crypto',
  ACCEPT_PRIVACY_POLICY: 'common.accept-privacy-policy',
  QIIBEE_PRIVACY_POLICY: 'qiibee Privacy Policy'
}

export const SERVER_ERROR_MESSAGES = {
  NFT_ALREADY_CLAIMED: 'NFT already claimed.',
  USER_HAS_ALREADY_USED_CODE: 'User has already used code'
}
