import React, { memo, useState, useEffect, useRef } from 'react'
import { Trans } from 'react-i18next'

import { formatTimeForTimer } from '../../util/rewardBox.helpers'
import { REWARD_BOX_PROCESSING_TIME } from '../../constants/rewardBox'

const RewardProcessingTimer = ({ endedTimer }) => {
  const [time, setTime] = useState(REWARD_BOX_PROCESSING_TIME)

  const processTimer = useRef(null)

  useEffect(() => {
    if (time > 0) {
      processTimer.current = setInterval(
        () => setTime((prevTime) => prevTime - 1),
        1000
      )
    }
    return () => {
      clearProcessInterval()
    }
  }, [])

  useEffect(() => {
    if (time <= 0) {
      endedTimer()
      clearProcessInterval()
    }
  }, [time])

  const clearProcessInterval = () => {
    clearInterval(processTimer.current)
  }

  return (
    <Trans
      i18nKey='reward-box.this-reward-box-is-opening'
      values={{ value: formatTimeForTimer(time) }}
    />
  )
}

export default memo(RewardProcessingTimer)
