import React, { memo } from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/react'

import { PanelMainContainer } from '../shared/styled/common'
import {
  getCustomTextColor,
  getCustomThemeColor
} from '../../util/style.helpers'
import { BW_COLOR_ACCENT, BW_COLOR_MAIN } from '../../constants/theme'
import { useSubmitAction } from '../../hooks/useSubmitAction'
import { DEFAULT_RESUBMIT_TIME } from '../../constants/app'
import { CSS_DISABLED } from '../../styles/shared'

const BWPanel = ({
  children,
  as,
  onFormSubmit,
  resubmitTime = DEFAULT_RESUBMIT_TIME,
  ...restProps
}) => {
  const isForm = as === 'form'
  const handleFormSubmit = useSubmitAction(resubmitTime, (e) => {
    onFormSubmit(e)
  })
  return (
    <PanelContainer
      data-testid='bw-panel'
      onSubmit={isForm ? handleFormSubmit : null}
      noValidate={isForm}
      as={as}
      {...restProps}
    >
      {children}
    </PanelContainer>
  )
}

BWPanel.defaultProps = {
  color: BW_COLOR_MAIN,
  anchor: false
}

export default memo(BWPanel)

export const PanelContainer = styled(PanelMainContainer)`
  ${(props) => panelDefaultStyles(props)}
`

const panelDefaultStyles = ({
  theme: { colors },
  gap,
  color,
  textColor,
  bgImage,
  disabled,
  invertBg
}) => {
  const customColor = invertBg
    ? color === BW_COLOR_ACCENT
      ? BW_COLOR_MAIN
      : BW_COLOR_ACCENT
    : color
  const themeColor = getCustomThemeColor({
    disabled,
    colors,
    color: customColor,
    noDefault: true
  })
  const textLabelColor = getCustomTextColor({
    disabled: disabled || bgImage,
    colors,
    color: textColor,
    themeColor
  })
  return css`
    color: ${(customColor === BW_COLOR_ACCENT && colors?.popupBg) ||
    textLabelColor};
    background-color: ${themeColor};
    ${gap && `gap: ${gap}px`};
    ${disabled && CSS_DISABLED};
  `
}
