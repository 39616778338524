import React, { memo } from 'react'
import { Trans } from 'react-i18next'

import CardWithBody from '../shared/panel/CardWithBody'
import ProgressBar from '../shared/progressBar/ProgressBar'
import { BWText } from 'src/libs/qb-brand-web-components'
import { percentageOfCompletion } from '../../util/number.helpers'
import BonusResetTimer from './BonusResetTimer'
import { buildPopupDetails } from '../../util/rewardBox.helpers'
import { REWARD_BOX_MILESTONE_ID } from '../../constants/rewardBox'

const MilestoneBonus = ({
  t,
  milestoneProfile: { data, achievedIndividual },
  symbol,
  onShowPopup
}) => {
  return (
    <CardWithBody
      title={t('reward-box.daily-milestone-bonuses')}
      desc={t('reward-box.open-reward-boxes')}
      subHeading={
        <BWText
          size={12}
          label={
            <>
              <>{t('reward-box.resets-in')}</>
              <BonusResetTimer />
            </>
          }
        />
      }
      body={data.individual.map(({ id, reward, threshold }, index) => {
        const completed = percentageOfCompletion(achievedIndividual, threshold)
        return (
          <ProgressBar
            key={index}
            leftLabel={'0'}
            rightLabel={threshold}
            completed={completed}
            onClick={
              completed >= 100
                ? () => {
                    onShowPopup(
                      buildPopupDetails(t, { id, reward, threshold }, symbol)
                    )
                  }
                : null
            }
            info={
              <BWText
                label={
                  <Trans
                    i18nKey={`reward-box.open-boxes-for-${id === REWARD_BOX_MILESTONE_ID.MILESTONE_2 ? 'crypto' : 'points'}`}
                    values={{
                      current: achievedIndividual,
                      target: threshold,
                      value: reward,
                      symbol
                    }}
                  />
                }
                alignCenter
              />
            }
          />
        )
      })}
    />
  )
}

export default memo(MilestoneBonus)
