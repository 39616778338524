import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { useTranslation } from 'react-i18next'
import {
  containsNoValues,
  emailRule,
  checkValidations,
  passwordRule,
  requiredRule,
  BW_AUTH_LANGUAGE_ATTR
} from 'src/libs/qb-brand-web-components'

import Signup from '../components/signup/Signup'
import {
  changeSignupState,
  changeSignupInputAndErrorState,
  cleanSignupState,
  signUp,
  resendSignupEmail,
  socialMediaLogIn,
  cleanLoginState
} from '../store/actions/auth'
import { SIGNUP_PROFILE_DISPATCH } from '../store/reducers/auth/signup'
import requireUnauth from '../hoc/requireUnauth'
import {
  AUTH_FIRST_NAME_ATTR,
  AUTH_LAST_NAME_ATTR,
  AUTH_REWARD_CODE_ATTR
} from '../constants/auth'
import { SignupSuccess } from '../components/popups/signUp'
import {
  DEFAULT_CONTAINER_STATE,
  SIGNUP_PROCESS_STEP
} from '../constants/containerStates'
import i18n from '../i18n'

const SignupContainer = ({
  signupProfile,
  changeSignupState,
  changeSignupInputAndErrorState,
  cleanLoginState,
  cleanSignupState,
  signUp,
  resendSignupEmail,
  rewardsProfile,
  socialMediaLogIn
}) => {
  const { t } = useTranslation()

  const { email, signupState } = signupProfile

  useEffect(() => {
    return () => {
      cleanSignupState()
      cleanLoginState()
    }
  }, [])

  useEffect(() => {
    setPopupState(signupState)
  }, [signupState])

  const [popupState, setPopupState] = useState(DEFAULT_CONTAINER_STATE.NONE)

  const handleSignupSubmit = () => {
    changeSignupState(SIGNUP_PROFILE_DISPATCH.ERROR, {})
    const { firstName, lastName, email, password } = signupProfile
    const errors = checkValidations({
      firstName: { value: firstName, rules: [requiredRule] },
      lastName: { value: lastName, rules: [requiredRule] },
      email: { value: email, rules: [requiredRule, emailRule] },
      password: { value: password, rules: [requiredRule, passwordRule] }
    })
    if (containsNoValues(errors)) {
      const attributes = {
        [AUTH_LAST_NAME_ATTR]: lastName,
        [AUTH_FIRST_NAME_ATTR]: firstName,
        [AUTH_REWARD_CODE_ATTR]: rewardsProfile.rewardCode,
        [BW_AUTH_LANGUAGE_ATTR]: i18n.language
      }
      signUp(email, password, attributes)
    } else {
      changeSignupState(SIGNUP_PROFILE_DISPATCH.ERROR, errors)
    }
  }

  const handleNoPopup = () => {
    setPopupState(DEFAULT_CONTAINER_STATE.NONE)
  }

  const handleCancelSuccessPopup = () => {
    changeSignupState(
      SIGNUP_PROFILE_DISPATCH.SIGNUP_STATE,
      DEFAULT_CONTAINER_STATE.NONE
    )
    handleNoPopup()
  }

  const handleResendVerification = () => {
    resendSignupEmail(email)
  }

  const handleSocialMediaLogin = (provider) => {
    socialMediaLogIn(provider)
  }

  const renderPopup = () => {
    switch (popupState) {
      case SIGNUP_PROCESS_STEP.SIGNUP_SUCCESS:
      case SIGNUP_PROCESS_STEP.RESEND_VERIFICATION_SUCCESS:
      case SIGNUP_PROCESS_STEP.RESEND_VERIFICATION_FAILED:
        return (
          <SignupSuccess
            signupState={signupState}
            email={email}
            onResendVerification={handleResendVerification}
            onCancel={handleCancelSuccessPopup}
            t={t}
          />
        )
      default:
        break
    }
  }
  return (
    <>
      <Signup
        signupProfile={signupProfile}
        onSignupSubmit={handleSignupSubmit}
        dispatchAction={changeSignupInputAndErrorState}
        onSocialMediaLogin={handleSocialMediaLogin}
        t={t}
      />
      {renderPopup()}
    </>
  )
}

const mapStateToProps = ({ authReducer, rewardsReducer }) => {
  const { signupProfile } = authReducer
  const { rewardsProfile } = rewardsReducer
  return {
    signupProfile,
    rewardsProfile
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      changeSignupState,
      changeSignupInputAndErrorState,
      cleanSignupState,
      signUp,
      resendSignupEmail,
      socialMediaLogIn,
      cleanLoginState
    },
    dispatch
  )

export default requireUnauth(
  connect(mapStateToProps, mapDispatchToProps)(SignupContainer)
)
