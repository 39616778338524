import React, { memo } from 'react'
import { BWPanel } from 'src/libs/qb-brand-web-components'

import { OverviewTitleContent } from '../shared/common'
import QuestsButton from './QuestsButton'
// @TODO: need to make central state for wallet and enable here
// import RewardBoxesButton from './RewardBoxesButton'
// import brandConfig from 'brandConfig'

const OverviewMainPanelContent = ({
  children,
  panelCss,
  navItemsMoreLength,
  customCss,
  isQuestsEnabled,
  t
}) => {
  return (
    <>
      <BWPanel padding={15} css={[{ maxHeight: '500px' }, panelCss]}>
        <OverviewTitleContent
          navItemsMoreLength={navItemsMoreLength}
          css={[customCss, isQuestsEnabled && { gap: 15, padding: 15 }]}
        >
          {children}
        </OverviewTitleContent>
      </BWPanel>
      {isQuestsEnabled && <QuestsButton t={t} />}
      {/* {brandConfig.features.rewardBoxes && <RewardBoxesButton t={t} />} */}
    </>
  )
}

export default memo(OverviewMainPanelContent)
