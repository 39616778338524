import React, { lazy } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { useScript } from 'src/libs/qb-brand-web-components'

import requireAuth from '../hoc/requireAuth'
import OverviewContainer from './OverviewContainer'
import RedeemContainer from './RedeemContainer'
import TransactionsContainer from './TransactionsContainer'
import UserAccountContainer from './UserAccountContainer'
import EngravingMessagesContainer from './EngravingMessagesContainer'
import {
  ACCOUNT_URL,
  HOME_URL,
  PAGE_NOT_FOUND_URL,
  REDEEM_POINTS_URL,
  TRANSACTIONS_URL,
  EXCHANGE_POINTS_URL,
  MANAGE_ACCOUNTS_URL,
  ENGRAVING_MESSAGES_URL,
  REDIRECT_URL,
  CASHBACK_URL,
  BUY_POINTS_URL,
  QUESTS_URL,
  REWARD_BOXES_URL
} from '../constants/navigation'
import { GOOGLE_RECAPTCHA_SCRIPT_URL, APP_CONFIG } from '../constants/appConfig'
import requireLanguage from '../hoc/requireLanguage'
import { nftLink } from '../util/nft.helpers'
import brandConfig from 'brandConfig'
import { isRewardsAvailable } from '../util/feature.helpers'
import PageRedirectContainer from './PageRedirectContainer'
import AuthorizedLayoutContainer from './AuthorizedLayoutContainer'
import RewardBoxesContainer from './RewardBoxesContainer'

const ExchangePointsContainer = lazy(() => import('./ExchangePointsContainer'))
const ManageAccountsContainer = lazy(() => import('./ManageAccountsContainer'))
const NftsContainer = lazy(() => import('./NftsContainer'))
const CashbackContainer = lazy(() => import('./CashbackContainer'))
const BuyPointsContainer = lazy(() => import('./BuyPointsContainer'))
const QuestsContainer = lazy(() => import('./QuestsContainer'))

const HomeContainer = ({ additionalRoutes, ...props }) => {
  useScript(
    `${GOOGLE_RECAPTCHA_SCRIPT_URL}${APP_CONFIG.RECAPTCHA_ID}`,
    'recaptcha'
  )
  const features = brandConfig.features
  const {
    isExchangeEnabled,
    isNftEnabled,
    brandAppConfig,
    enablePurchasePoints,
    isQuestsEnabled
  } = props
  return (
    <AuthorizedLayoutContainer {...props}>
      <Routes>
        {!features.disablePoints && (
          <Route
            path={HOME_URL}
            element={
              <OverviewContainer
                isExchangeEnabled={isExchangeEnabled}
                isNftEnabled={isNftEnabled}
                isQuestsEnabled={isQuestsEnabled}
              />
            }
          />
        )}
        <Route path={TRANSACTIONS_URL} element={<TransactionsContainer />} />
        <Route
          path={ACCOUNT_URL}
          element={
            <UserAccountContainer isExchangeEnabled={isExchangeEnabled} />
          }
        />
        {isRewardsAvailable() && (
          <Route path={REDEEM_POINTS_URL} element={<RedeemContainer />} />
        )}
        {isExchangeEnabled && !features.disableExchangeLink && (
          <Route
            path={EXCHANGE_POINTS_URL}
            element={<ExchangePointsContainer />}
          />
        )}
        {isExchangeEnabled && (
          <Route
            path={`${MANAGE_ACCOUNTS_URL}/:id?`}
            element={<ManageAccountsContainer />}
          />
        )}
        {isNftEnabled && (
          <Route path={`${nftLink()}/:nftId?`} element={<NftsContainer />} />
        )}
        {brandAppConfig.cashbackEarnOption && (
          <Route path={CASHBACK_URL} element={<CashbackContainer />} />
        )}
        {features.showEngravingMessages && (
          <Route
            path={ENGRAVING_MESSAGES_URL}
            element={<EngravingMessagesContainer />}
          />
        )}
        <Route
          path={`${REDIRECT_URL}/:path?/:brand?`}
          element={<PageRedirectContainer />}
        />
        {enablePurchasePoints && (
          <Route path={BUY_POINTS_URL} element={<BuyPointsContainer />} />
        )}
        {isQuestsEnabled && (
          <Route path={QUESTS_URL} element={<QuestsContainer />} />
        )}
        {features.rewardBoxes && (
          <Route path={REWARD_BOXES_URL} element={<RewardBoxesContainer />} />
        )}
        {additionalRoutes}
        <Route
          path='*'
          element={<Navigate to={PAGE_NOT_FOUND_URL} replace />}
        />
      </Routes>
    </AuthorizedLayoutContainer>
  )
}

export default requireAuth(requireLanguage(HomeContainer))
