export const MIN_USD_FOR_EXCHANGE = 2
export const MAX_CRYPTO_DECIMALS = 1000000
export const QB_FEE_BUY_POINTS = 0.05 // 5% qiibee fee for buy points with credit card
// @TODO: Changed crypto exchange rates polling time from 30 to 60 seconds revisit if there is any issue and change
export const CRYPTO_EXCHANGE_RATES_INTERVAL = 60 // seconds

export const MIN_USD_EXCHANGE_LIMIT = 10
export const MIN_USD_EXCHANGE_LIMIT_FOR_MILES = 25
export const MAX_USD_EXCHANGE_LIMIT = 100

export const CUSTOM_BRAND_ID = {
  MILES_AND_MORE: 'miles_and_more',
  ETIHAD: 'etihad',
  TROLLBEADS_MEMBERSHIP_CLUB: 'trollbeads_membership_club',
  COINBASE: 'coinbase',
  BINANCE: 'binance',
  PAYPAL: 'paypal',
  XOXO_DAY: 'xoxoday',
  GET_CHANGE: 'get_change',
  ETH_ADDRESS: 'eth_address',
  TELEGRAM: 'telegram',
  X: 'x',
  QBX_TAP: 'qbx_tap'
}

export const EMAIL_TYPE_EXCHANGE_PROVIDERS = [
  CUSTOM_BRAND_ID.COINBASE,
  CUSTOM_BRAND_ID.BINANCE,
  CUSTOM_BRAND_ID.PAYPAL,
  CUSTOM_BRAND_ID.TROLLBEADS_MEMBERSHIP_CLUB,
  CUSTOM_BRAND_ID.XOXO_DAY,
  CUSTOM_BRAND_ID.GET_CHANGE
]

export const EXCHANGE_PROVIDER_DATA = {
  [CUSTOM_BRAND_ID.MILES_AND_MORE]: {
    type: 'Award'
  },
  [CUSTOM_BRAND_ID.ETIHAD]: {
    type: 'Guest'
  }
}

export const POINTS_EXCHANGE_TOGGLE = {
  CONNECT_ACCOUNT: 'connectAccount',
  EXCHANGE_POINTS: 'exchangePoints',
  SELECT_CRYPTO_EXCHANGE: 'selectCryptoExchange'
}

export const POINTS_EXCHANGE_FAVORITE = {
  SUBMIT_FAVORITE: 'submitFavorite',
  VIEW_SUBMISSIONS: 'viewSubmissions'
}

export const COINBASE_URL = 'https://www.coinbase.com/'
export const COINBASE_SIGNUP_URL = `${COINBASE_URL}join/3s7rso`
export const COINBASE_BLUR_URL = 'https://www.coinbase.com/price/blur'

export const BINANCE_URL = 'https://www.binance.com/en/'
export const BINANCE_SIGNUP_URL = `${BINANCE_URL}activity/referral-entry/CPA?fromActivityPage=true&ref=CPA_00AN2NOBYC`
export const BINANCE_PARTNER_PROGRAM_URL = BINANCE_SIGNUP_URL // `${BINANCE_URL}gift-card` @TODO: Need to revert later

export const DONATION_WEBSITE_URLS = {
  alz: 'https://www.alz.org',
  fcr: 'https://www.letsfcancer.com/',
  red: 'https://www.redcross.org/',
  stcf: 'https://www.savethechildren.org/',
  sam: 'https://www.samaritansri.org/',
  unic: 'https://www.unicefusa.org/',
  dwb: 'https://www.doctorswithoutborders.org/',
  wwf: 'https://www.worldwildlife.org/',
  dir: 'https://www.directrelief.org/',
  otp: 'http://www.onetreeplanted.org/'
}

export const BINANCE_VIEW_SHOP_URLS = {
  btc: 'https://academy.binance.com/en/articles/what-is-bitcoin',
  eth: 'https://academy.binance.com/en/articles/what-is-ethereum',
  bnb: 'https://research.binance.com/en/projects/bnb',
  og: 'https://research.binance.com/en/projects/og-fan-token',
  city: 'https://research.binance.com/en/projects/manchester-city-fan-token',
  bar: 'https://research.binance.com/en/projects/fc-barcelona-fan-token',
  psg: 'https://research.binance.com/en/projects/paris-saint-germain-fan-token',
  acm: 'https://research.binance.com/en/projects/ac-milan-fan-token',
  juv: 'https://research.binance.com/en/projects/juventus-fan-token',
  dot: 'https://academy.binance.com/en/articles/what-is-polkadot-dot',
  doge: 'https://academy.binance.com/en/articles/what-is-dogecoin',
  uni: 'https://academy.binance.com/en/articles/what-is-uniswap-and-how-does-it-work',
  matic: 'https://academy.binance.com/en/articles/what-is-polygon-matic',
  axs: 'https://academy.binance.com/en/articles/what-is-axie-infinity-axs',
  icp: 'https://research.binance.com/en/projects/dfinity',
  alpine:
    'https://academy.binance.com/en/articles/what-is-alpine-f1-team-fan-token-alpine'
}

export const BUY_POINTS_PROVIDER = {
  CREDIT_CARD: {
    id: 'creditCard',
    symbol: 'USD',
    minUsdExchangeLimit: MIN_USD_EXCHANGE_LIMIT,
    maxUsdExchangeLimit: MAX_USD_EXCHANGE_LIMIT
  }
}

export const EARN_REWARD_OPTION = {
  BUY_POINTS: 'buyPoints',
  CASHBACK: 'cashback'
}
