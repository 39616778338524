import { deepMerge } from 'src/libs/qb-brand-web-components'
import { faBooks, faBook } from '@fortawesome/pro-light-svg-icons'

import defaultConfig from '../common/customConfig'

const customConfig = {
  nftsIcon: faBooks,
  nftIcon: faBook,
  enterRewardCodeLabel: 'brand:reward.enter-reward-code',
  overviewEnterRewardCodeLabel: 'brand:reward.enter-reward-code',
  receiveRewardLabel: 'brand:reward.receive-reward'
}

export default deepMerge(defaultConfig, customConfig)
