import React, { memo } from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/react'

import {
  CSS_CURSOR_POINTER,
  cssFontSize,
  cssFontWeight,
  cssCustomFontSize,
  CSS_OUTLINE_NONE,
  CSS_TEXT_ALIGN_CENTER
} from '../../styles/shared'
import { SIZE_UNIT } from '../../themes/units'
import brandConfig from 'brandConfig'

const BWLink = ({ label, customCss, ...restProps }) => {
  return (
    <LinkContainer data-testid='bw-link' css={customCss} {...restProps}>
      {label}
    </LinkContainer>
  )
}

export default memo(BWLink)

export const LinkContainer = styled.a`
  &,
  &:active,
  :visited,
  :focus {
    text-decoration: ${brandConfig.linkTextDecoration || 'none'};
    ${CSS_OUTLINE_NONE};
    ${cssFontSize(SIZE_UNIT.MD)};
    ${cssFontWeight(SIZE_UNIT.LG)};
    ${CSS_CURSOR_POINTER};
    ${({ theme, size, color, alignCenter }) =>
      defaultLinkStyles(theme.colors, size, color, alignCenter)};
  }
  &:hover {
    text-decoration: underline;
  }
`

const defaultLinkStyles = (colors, size, themeColor, alignCenter) => {
  return css`
    color: inherit;
    ${themeColor && `color: ${colors[themeColor]}`};
    ${alignCenter && CSS_TEXT_ALIGN_CENTER};
    ${cssCustomFontSize(size)};
  `
}
