import React, { memo } from 'react'
import { BWPopup } from 'src/libs/qb-brand-web-components'

import brandConfig from 'brandConfig'
import customConfig from 'customConfig'

const Popup = ({ children, ...restProps }) => {
  return (
    <BWPopup show {...restProps}>
      {children}
    </BWPopup>
  )
}

const { prefColor, prefTextColor } = brandConfig
const { isRectangular, textTransform, isCustom, buttonStyles } =
  customConfig.customCss

Popup.defaultProps = {
  btnTextColor: prefTextColor,
  btnColor: prefColor,
  isRectangular: isRectangular,
  textTransform: textTransform,
  isCustom: isCustom,
  submitBtnProps: { css: buttonStyles }
}

export default memo(Popup)
