import { API_RESPONSE } from '../constants/api'
import { APP_CONFIG } from '../constants/appConfig'
import { DYNAMIC_PAGE_REDIRECT_PATHS } from '../constants/externalUrls'
import { ERROR_TEXT } from '../constants/messages'
import { REDIRECT_URL } from '../constants/navigation'
import { BW_ERROR_TEXT } from '../libs/qb-brand-web-components'
import { buildDropdownList } from './app/app.helpers'

const QUEST_VERIFY_DISABLE_TIME = 10000

const QUEST_FILTER_TYPE = {
  ACTIVE: 'active',
  COMPLETED: 'completed'
}

const buildQuestFilterOptions = (t) => {
  return buildDropdownList(QUEST_FILTER_TYPE, 'quest.filters', t)
}

const formatQuests = (data = []) => {
  const result = {
    active: [],
    completed: []
  }
  data.forEach((record) => {
    if (record?.questRewards?.length && record.questRewards[0].verified) {
      result.completed.push(record)
    } else {
      result.active.push(record)
    }
  })
  return result
}

const questFulfillUrl = (id, authToken) => {
  return `${APP_CONFIG.SLS_URL}/quests/r/${id}?auth=${authToken}`
}

const questRedirectUrl = (id) => {
  return `${REDIRECT_URL}/${DYNAMIC_PAGE_REDIRECT_PATHS.QUEST}/${id}`
}

const extractQuestRewardError = (payload) => {
  const status = payload?.status
  if (status === API_RESPONSE.BAD_REQUEST) {
    return ERROR_TEXT.FAILED_TO_VERIFY_CLAIM_REWARD
  }
  return BW_ERROR_TEXT.SOMETHING_WENT_WRONG
}

export {
  QUEST_VERIFY_DISABLE_TIME,
  QUEST_FILTER_TYPE,
  formatQuests,
  buildQuestFilterOptions,
  questFulfillUrl,
  questRedirectUrl,
  extractQuestRewardError
}
