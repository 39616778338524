import React, { memo, useEffect, useState } from 'react'

import { nextResetTime } from '../../util/rewardBox.helpers'

const BonusResetTimer = () => {
  const [nextPaymentTime, setNextPaymentTime] = useState(nextResetTime())
  useEffect(() => {
    const nextResetInterval = setInterval(
      () => setNextPaymentTime(nextResetTime()),
      1000
    )
    return () => {
      clearInterval(nextResetInterval)
    }
  }, [])
  return <>{nextPaymentTime}</>
}

export default memo(BonusResetTimer)
