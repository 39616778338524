import {
  keysToCamel,
  formatCurrencyValue,
  TRANSACTION_STATUS
} from 'src/libs/qb-brand-web-components'

import { isExchangeEventType } from './pointsExchange.helpers'
import { toLower } from './string.helpers'

const parseTransaction = (transaction) => {
  if (!transaction) {
    return {}
  }
  const parsedTransaction = commonParser(transaction)
  const { loyaltyEventType, amountReceived } = parsedTransaction
  if (loyaltyEventType) {
    return {
      ...parsedTransaction,
      amountReceived: formatCurrencyValue(amountReceived, 6),
      status: buildTransactionStatus(parsedTransaction)
    }
  }
  return {}
}

const parseTransactionResponse = (tx) => {
  if (!tx) {
    return {}
  }
  const { total_tx_count, ...restAttributes } = tx
  return {
    totalTxCount: total_tx_count,
    ...restAttributes
  }
}

const parseMembership = (membership) => {
  if (!membership) {
    return {}
  }
  const { id, membership_number, brand } = membership
  if (membership_number && brand) {
    return {
      id,
      membershipNumber: membership_number,
      brand: commonParser(brand)
    }
  }
  return {}
}

const parseToken = (token) => {
  if (!token) {
    return {}
  }
  if (token.status) {
    token.status = token.status.toLowerCase()
  }
  return keysToCamel(token)
}

const commonParser = (data) => {
  if (!data) {
    return {}
  }
  return keysToCamel(data)
}

export {
  parseTransaction,
  parseMembership,
  parseToken,
  parseTransactionResponse,
  commonParser
}

const buildTransactionStatus = ({ status, txHash, loyaltyEventType }) => {
  const txnStatus = toLower(status)
  if (isExchangeEventType(loyaltyEventType)) {
    return txnStatus || TRANSACTION_STATUS.SUCCESS // @TODO: need to check with BE response and update the logic here.
  }
  if (txHash) {
    return TRANSACTION_STATUS.SUCCESS
  }
  return txnStatus
}
