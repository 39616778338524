import { REWARD_BOX_ACTION } from '../../constants/actions'

const getMilestone = (address) => ({
  type: REWARD_BOX_ACTION.GET_MILESTONE,
  payload: { address }
})

const increaseMilestone = () => ({
  type: REWARD_BOX_ACTION.INCREASE_MILESTONE
})

const startJackpotWinnerPolling = () => ({
  type: REWARD_BOX_ACTION.START_JACKPOT_WINNER_POLLING
})

const stopJackpotWinnerPolling = () => ({
  type: REWARD_BOX_ACTION.STOP_JACKPOT_WINNER_POLLING
})

const changeMilestoneState = (property, value) => ({
  type: REWARD_BOX_ACTION.CHANGE_STATE,
  payload: {
    property,
    value
  }
})

export {
  getMilestone,
  increaseMilestone,
  startJackpotWinnerPolling,
  stopJackpotWinnerPolling,
  changeMilestoneState
}
