import { stringToBool } from 'src/libs/qb-brand-web-components'

import { errorLogger } from './app/app.helpers'

const REWARD_CODE = 'rewardCode'
const EMAIL_MISSING = 'emailMissing'
const FORCE_SIGNUP = 'forceSignup'

const setRewardCode = (rewardCode) => {
  try {
    localStorage.setItem(REWARD_CODE, rewardCode)
  } catch (e) {
    errorLogger(e)
  }
}

const getRewardCode = () => {
  return localStorage.getItem(REWARD_CODE) || ''
}

const removeRewardCode = () => {
  localStorage.removeItem(REWARD_CODE)
}

const setEmailMissing = (emailMissing) => {
  try {
    localStorage.setItem(EMAIL_MISSING, emailMissing)
  } catch (e) {
    errorLogger(e)
  }
}

const getEmailMissing = () => {
  return stringToBool(localStorage.getItem(EMAIL_MISSING)) || false
}

const removeEmailMissing = () => {
  localStorage.removeItem(EMAIL_MISSING)
}

const setForceSignup = (forceSignup) => {
  try {
    localStorage.setItem(FORCE_SIGNUP, forceSignup)
  } catch (e) {
    errorLogger(e)
  }
}

const getForceSignup = () => {
  return stringToBool(localStorage.getItem(FORCE_SIGNUP)) || false
}

const removeForceSignup = () => {
  localStorage.removeItem(FORCE_SIGNUP)
}

const setLocalStorage = (name, value) => {
  localStorage.setItem(name, value)
}

const getLocalStorage = (name) => {
  return stringToBool(localStorage.getItem(name)) || false
}

export {
  setRewardCode,
  getRewardCode,
  removeRewardCode,
  setEmailMissing,
  getEmailMissing,
  removeEmailMissing,
  setForceSignup,
  getForceSignup,
  removeForceSignup,
  setLocalStorage,
  getLocalStorage
}
