import React, { Suspense, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { ThemeProvider, Global } from '@emotion/react'
import { defaultTheme, deepMerge } from 'src/libs/qb-brand-web-components'
import { I18nextProvider } from 'react-i18next'
import { hotjar } from 'react-hotjar'
import ReactGA from 'react-ga4'
import ReactPixel from 'react-facebook-pixel'
import GoogleTagManager from 'react-gtm-module'

import brandConfig from 'brandConfig'
import i18n from './i18n'
import { APP_CONFIG } from './constants/appConfig'
import AppRoutes from './AppRoutes'
import Loading from './components/shared/loading/Loading'
import { isRunningTesting } from './util/app/app.helpers'
import { globalStyles } from './styles/global'
import { clearCacheOnNewAppVersion } from './util/cache.helpers'
// import { useServiceWorker } from './hooks/useServiceWorker'
// import AppRefresh from './components/popups/common/AppRefresh'

const Root = () => {
  const location = useLocation()
  useEffect(() => {
    clearCacheOnNewAppVersion()

    if (!isRunningTesting()) {
      // Facebook Pixel
      const facebookPixelId = APP_CONFIG.FACEBOOK_PIXEL_ID
      if (facebookPixelId) {
        ReactPixel.init(facebookPixelId, null, {
          autoConfig: true,
          debug: false
        })
        ReactPixel.pageView()
        ReactPixel.track('PageView')
      }

      // Google Tag Manager
      const googleTagManagerId = APP_CONFIG.GOOGLE_TAG_MANAGER_ID
      if (googleTagManagerId) {
        GoogleTagManager.initialize({ gtmId: googleTagManagerId })
      }

      // HotJar
      const hotjarConfig = APP_CONFIG.HOTJAR_CONFIG
      if (brandConfig.hotjarId && hotjarConfig.version) {
        hotjar.initialize(brandConfig.hotjarId, hotjarConfig.version)
      }
    }
  }, [])

  useEffect(() => {
    if (!isRunningTesting() && location) {
      // Google Analytics
      const gaTrackingId = APP_CONFIG.GA_TRACKING_ID
      if (gaTrackingId) {
        ReactGA.initialize(gaTrackingId)
        ReactGA.send({ hitType: 'pageview', page: location.pathname })
      }
    }
  }, [location])

  // @TODO: remove this before production
  // const { waitingWorker, showReload, reloadPage } = useServiceWorker()

  return (
    <ThemeProvider theme={deepMerge(defaultTheme, brandConfig.theme)}>
      <I18nextProvider i18n={i18n}>
        <Global styles={globalStyles} />
        <Suspense fallback={<Loading main />}>
          <AppRoutes />
          {/* {showReload && waitingWorker && (
            <AppRefresh onSubmit={() => reloadPage()} />
          )} */}
        </Suspense>
      </I18nextProvider>
    </ThemeProvider>
  )
}

export default Root
