import React, { useEffect, useState, lazy } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { useTranslation } from 'react-i18next'
import { faCirclePlus } from '@fortawesome/pro-light-svg-icons'
import { useLocation, useNavigate } from 'react-router-dom'

import { RewardFromCodeFailed } from '../components/popups/reward'
import { changeRewardsState } from '../store/actions/rewards'
import { resetPreviousUserPoints } from '../store/actions/account'
import { REWARDS_PROFILE_DISPATCH } from '../store/reducers/rewards/rewards'
import {
  BUY_POINTS_URL,
  EXCHANGE_POINTS_URL,
  NFTS_URL
} from '../constants/navigation'
import {
  REWARD_POINTS_PROCESS_STEP,
  DEFAULT_CONTAINER_STATE,
  REWARD_NFT_PROCESS_STEP
} from '../constants/containerStates'
import { useReceiveReward } from '../hooks/useReceiveReward'
import overviewPointsLink from 'wrappers/overviewPointsLink'
import overviewExchangeLink from 'wrappers/overviewExchangeLink'
import OverviewWrapper from 'wrappers/OverviewWrapper'
import { isRewardsAvailable } from '../util/feature.helpers'
import overviewNftLink from 'wrappers/overviewNftLink'
import { trackGTMDataLayer } from 'wrappers/trackGTMDataLayer'
import overviewCashbackLink from 'wrappers/overviewCashbackLink'
import HowToEarnPopupWrapper from 'wrappers/HowToEarnPopupWrapper'
import brandConfig from 'brandConfig'

const NftCodeSuccess = lazy(
  () => import('../components/popups/nft/NftCodeSuccess')
)
const ReceivePointsSuccess = lazy(
  () => import('../components/popups/reward/ReceivePointsSuccess')
)

const OverviewContainer = ({
  changeRewardsState,
  rewardsProfile,
  mainProfile,
  tokenProfile,
  resetPreviousUserPoints,
  isExchangeEnabled,
  isNftEnabled,
  isQuestsEnabled
}) => {
  const isExchangeAvailable =
    isExchangeEnabled && !brandConfig.features.disableExchangeLink
  const { t } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()
  useEffect(() => {
    trackGTMDataLayer(location.pathname)
  }, [])

  const { currentUserPoints, previousUserPoints, brandAppConfig } = mainProfile

  const [popupState, setPopupState] = useState(DEFAULT_CONTAINER_STATE.NONE)
  const [
    handleEnterRewardCodeClick,
    renderRewardPopup,
    rewardPopupState,
    setRewardPopupState
  ] = useReceiveReward(t, rewardsProfile)

  useEffect(() => {
    const historyState = location.state
    if (
      rewardsProfile.rewardCode &&
      historyState &&
      historyState.alreadyAuthenticated
    ) {
      setRewardPopupState(REWARD_POINTS_PROCESS_STEP.ENTER_CODE)
    }
  }, [])

  useEffect(() => {
    setPopupState(rewardPopupState)
  }, [rewardPopupState])

  useEffect(() => {
    return () => {
      resetCodeState()
      resetPreviousUserPoints()
    }
  }, [changeRewardsState])

  useEffect(() => {
    if (rewardsProfile.codeValidityState) {
      setPopupState(rewardsProfile.codeValidityState)
    }
  }, [rewardsProfile.codeValidityState])

  const handleClickHowToEarn = () => {
    setPopupState(REWARD_POINTS_PROCESS_STEP.HOW_TO_EARN_REWARDS)
  }

  const handleNoPopup = () => {
    resetRewardCode()
    changeRewardsState(REWARDS_PROFILE_DISPATCH.ERROR, {})
    setPopupState(DEFAULT_CONTAINER_STATE.NONE)
  }

  const resetCodeState = () => {
    changeRewardsState(
      REWARDS_PROFILE_DISPATCH.CODE_VALIDITY_STATE,
      DEFAULT_CONTAINER_STATE.NONE
    )
  }

  const resetRewardCode = () => {
    changeRewardsState(REWARDS_PROFILE_DISPATCH.REWARD_CODE, '')
  }

  const handlePopupSubmit = () => {
    switch (popupState) {
      case REWARD_POINTS_PROCESS_STEP.REWARD_FROM_CODE_FAILED:
        handleNoPopup()
        break

      case REWARD_NFT_PROCESS_STEP.NFT_RECEIVED:
        resetRewardCode()
        resetCodeState()
        handleNoPopup()
        if (!rewardsProfile.reward?.campaign?.deltaSeconds) {
          navigate(NFTS_URL)
        }
        break

      case REWARD_POINTS_PROCESS_STEP.POINTS_RECEIVED:
        handleNoPopup()
        if (
          !rewardsProfile.reward?.campaign?.deltaSeconds &&
          isExchangeAvailable
        ) {
          navigate(EXCHANGE_POINTS_URL)
        }
        break

      default:
        break
    }
  }

  const handlePopupCancel = () => {
    switch (popupState) {
      case REWARD_POINTS_PROCESS_STEP.HOW_TO_EARN_REWARDS:
      case REWARD_POINTS_PROCESS_STEP.POINTS_RECEIVED:
        handleNoPopup()
        break

      default:
        break
    }
  }

  const renderPopup = () => {
    const { reward } = rewardsProfile
    switch (popupState) {
      case REWARD_POINTS_PROCESS_STEP.REWARD_FROM_CODE_FAILED:
        return (
          <RewardFromCodeFailed
            onSubmit={handlePopupSubmit}
            tokenName={tokenProfile.name}
            t={t}
          />
        )

      case REWARD_POINTS_PROCESS_STEP.POINTS_RECEIVED:
        return (
          <ReceivePointsSuccess
            reward={reward}
            onSubmit={handlePopupSubmit}
            tokenProfile={tokenProfile}
            t={t}
            isExchangeAvailable={isExchangeAvailable}
            onCancel={handlePopupCancel}
          />
        )

      case REWARD_NFT_PROCESS_STEP.NFT_RECEIVED:
        return (
          <NftCodeSuccess
            onAwesomeClick={handlePopupSubmit}
            reward={reward}
            t={t}
          />
        )

      case REWARD_POINTS_PROCESS_STEP.HOW_TO_EARN_REWARDS:
        return (
          <HowToEarnPopupWrapper
            onCancel={handlePopupCancel}
            tokenProfile={tokenProfile}
            cashbackEnabled={brandAppConfig.cashbackEarnOption}
            t={t}
          />
        )

      default:
        break
    }
  }

  return (
    <>
      <OverviewWrapper
        currentUserPoints={currentUserPoints}
        previousUserPoints={previousUserPoints}
        onEnterRewardCodeClick={handleEnterRewardCodeClick}
        onClickHowToEarn={handleClickHowToEarn}
        t={t}
        navigationItems={buildNavigationLinks(
          t,
          isExchangeAvailable,
          isNftEnabled,
          tokenProfile.enablePurchasePoints,
          brandAppConfig.cashbackEarnOption
        )}
        tokenProfile={tokenProfile}
        isQuestsEnabled={isQuestsEnabled}
      />
      {renderPopup()}
      {renderRewardPopup()}
    </>
  )
}

const mapStateToProps = ({ mainReducer, rewardsReducer }) => {
  const { mainProfile, tokenProfile } = mainReducer
  const { rewardsProfile } = rewardsReducer
  return {
    rewardsProfile,
    mainProfile,
    tokenProfile
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      changeRewardsState,
      resetPreviousUserPoints
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(OverviewContainer)

const buildNavigationLinks = (
  t,
  isExchangeAvailable,
  isNftEnabled,
  enablePurchasePoints,
  cashbackEarnOption
) => {
  const navigationItems = []
  if (isRewardsAvailable()) {
    navigationItems.push(overviewPointsLink(t))
  }
  if (isExchangeAvailable) {
    navigationItems.push(overviewExchangeLink(t))
  }
  if (isNftEnabled) {
    navigationItems.push(overviewNftLink(t))
  }
  if (enablePurchasePoints) {
    navigationItems.push({
      label: t('buy-points.buy-points'),
      icon: faCirclePlus,
      to: BUY_POINTS_URL
    })
  }
  if (cashbackEarnOption) {
    navigationItems.push(overviewCashbackLink(t))
  }
  return navigationItems
}
