import commaNumber from 'comma-number'

import customConfig from 'customConfig'

const toLower = (text) => {
  return text?.toLowerCase()
}

const toDashCase = (text) => {
  return toLower(text?.replace(/\s+/g, '-'))
}

const isUrlPatternMatching = (url, part) => {
  if (!url) {
    return ''
  }
  return toLower(url).indexOf(toLower(part)) !== -1
}

const removeTicks = (str) => {
  return str
    .split('')
    .filter((el) => el !== customConfig.currencyDelimiter)
    .join('')
}

const formatTicks = (num) => {
  if (num === undefined) {
    return ''
  }
  return commaNumber(num, customConfig.currencyDelimiter)
}

const snakeCaseToDashCase = (text) => {
  return text?.replace(/_+/g, '-')
}

const removeFirstSlash = (str) => {
  return str.startsWith('/') ? str.slice(1) : str
}

export {
  toLower,
  toDashCase,
  isUrlPatternMatching,
  formatTicks,
  removeTicks,
  snakeCaseToDashCase,
  removeFirstSlash
}
