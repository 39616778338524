import React, { memo } from 'react'
import { BWButton } from 'src/libs/qb-brand-web-components'

import brandConfig from 'brandConfig'
import customConfig from 'customConfig'

const { isRectangular, textTransform, buttonStyles } = customConfig.customCss

const Button = (props) => {
  return <BWButton {...props} {...(!props.noBg && { css: buttonStyles })} />
}

const { prefColor, prefTextColor } = brandConfig

Button.defaultProps = {
  textColor: prefTextColor,
  color: prefColor,
  invert: false,
  isRectangular: isRectangular,
  textTransform: textTransform
}

export default memo(Button)
