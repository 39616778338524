import { BW_LANGUAGE_IT } from 'src/libs/qb-brand-web-components'

const brandConfig = {
  brandId: 'howtospendit',
  name: 'How To Spend It',
  altName: 'Il Sole 24 Ore',
  faqEmail: 'welcome@howtospendit.it',
  websiteUrl: 'https://howtospenditmagazine.ilsole24ore.com/',
  privacyPolicyUrl: 'https://www.qiibee.com/privacy-policy/',
  cookiePolicyUrl: 'https://static.qiibee.com/rewards/cookiepolicy.pdf',
  engravingsUrl:
    'https://howtospendit.nft.ilsole24ore.com/static/media/assets/engravings.json',
  theme: {
    colors: {
      main: '#000000',
      accent: '#CACBC5'
    }
  },
  supportedLanguages: [BW_LANGUAGE_IT],
  features: {
    showEngravingMessages: true,
    disableWelcomeScreen: true,
    enableMandatoryCheckbox: true
  },
  cognito: {
    region: 'eu-central-1',
    poolId: 'eu-central-1_kCu5Aaxnm',
    clientId: '1kutomr47302d10epf4j4mshkf',
    poolDomain: 'howtospendit.auth.eu-central-1.amazoncognito.com',
    redirectUri: 'https://howtospendit.nft.ilsole24ore.com/login'
  },
  gaTrackingId: 'G-YYJVJ7H6XH',
  hotjarId: '2876191',
  facebookPixelId: '437969720190785'
}

export default brandConfig
