const titleCase = (str) => {
  return String(str)
    .toLowerCase()
    .split(' ')
    .map((word) => word && word.replace(word[0], word[0].toUpperCase()))
    .join(' ')
}

const toUpper = (text) => {
  return text ? text.toUpperCase() : ''
}

function extractEmailFromUrl(searchString) {
  const searchDropFirstChar = searchString.slice(1, -1)
  const options = searchDropFirstChar.split('&')

  return options.reduce((curr, next) => {
    const [value, key] = next.split('=')
    if (value === 'email') {
      return key
    } else {
      return curr
    }
  }, '')
}

const extractCodeOrInputText = (text) => {
  try {
    const url = new URL(text)
    return url.searchParams.get('code')
  } catch (e) {
    return text
  }
}

const toLowerCamelCase = (s) => {
  return s.replace(/([-_][a-z])/gi, ($1) => {
    return $1.toUpperCase().replace('-', '').replace('_', '')
  })
}

const removeProtocolFromURL = (url) => {
  return url.replace(/(^\w+:|^)\/\//, '')
}

const stringToBool = (label) => {
  return label === 'true'
}

const isString = (value) => {
  return typeof value === 'string'
}

export {
  titleCase,
  toUpper,
  extractEmailFromUrl,
  extractCodeOrInputText,
  toLowerCamelCase,
  removeProtocolFromURL,
  stringToBool,
  isString
}
