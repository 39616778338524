import moment from 'moment'

import {
  DEFAULT_ETH_PRICE,
  REWARD_BOX_MILESTONE_ID,
  WALLET_ACCOUNT_STATUS
} from '../constants/rewardBox'
import { formatTimeDelta } from 'src/libs/qb-brand-web-components'
import { commonParser } from './apiParser.helpers'
import { formatTicks } from './string.helpers'

const nextResetTime = () => {
  const currentDate = moment.utc()
  const nextTarget = moment.utc().startOf('day').add(1, 'days')
  return formatTimeDelta(nextTarget.diff(currentDate))
}

const WALLET_ERRORS = {
  USER_REJECTED_REQUEST: 'User rejected the request.'
}

const buildTransactionErrorMessage = (message) => {
  return message === WALLET_ERRORS.USER_REJECTED_REQUEST
    ? 'reward-box.rejected-transaction-request'
    : message || 'error.something-went-wrong'
}

const isWalletAccountConnected = ({ status, address }) => {
  return status === WALLET_ACCOUNT_STATUS.CONNECTED && address
}

const getEthPriceForRewardBox = (cryptoExchanges = {}) => {
  const ethPrice = cryptoExchanges.eth?.coinbase
  if (typeof ethPrice === 'number' && ethPrice > 0) {
    const discount = (ethPrice * 5) / 100
    return ethPrice - discount
  }
  return DEFAULT_ETH_PRICE // Fallback if ethPrice is not available
}

const formatTimeForTimer = (time) => {
  const minutes = Math.floor(time / 60)
  const seconds = time % 60
  return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`
}

const formatMilestones = (data = [], harvestBooster) => {
  const milestones = data.reduce(
    (result, record) => {
      const parsedRecord = commonParser(record)
      if (parsedRecord.type === 'community') {
        result.community.push(parsedRecord)
      } else if (parsedRecord.type === 'individual') {
        result.individual.push(parsedRecord)
      }
      return result
    },
    { individual: [], community: [] }
  )

  milestones.individual = sortIndividualMilestones(
    milestones.individual,
    harvestBooster
  )
  return milestones
}

const sortIndividualMilestones = (data = [], harvestBooster) => {
  const sortOrder = [
    REWARD_BOX_MILESTONE_ID.MILESTONE_1,
    REWARD_BOX_MILESTONE_ID.HARVEST_BOOSTER,
    REWARD_BOX_MILESTONE_ID.MILESTONE_2
  ]
  return data
    .filter(
      ({ id }) =>
        id !== REWARD_BOX_MILESTONE_ID.HARVEST_BOOSTER || harvestBooster >= 1
    )
    .sort((a, b) => sortOrder.indexOf(a.id) - sortOrder.indexOf(b.id))
}

const buildPopupDetails = (t, { id, threshold, reward }, symbol) => {
  switch (id) {
    case REWARD_BOX_MILESTONE_ID.MILESTONE_1:
      return {
        title: t('reward-box.lets-go'),
        desc: t('reward-box.you-opened', {
          value: threshold
        }),
        value: reward
      }

    case REWARD_BOX_MILESTONE_ID.HARVEST_BOOSTER:
      return {
        title: t('reward-box.harvest-booster'),
        desc: t('reward-box.you-opened', {
          value: threshold
        }),
        value: reward
      }

    case REWARD_BOX_MILESTONE_ID.MILESTONE_2:
      return {
        title: t('reward-box.points-crypto-unlocked', { symbol }),
        desc: t('reward-box.you-opened-for-crypto', {
          symbol,
          value: threshold
        }),
        goToExchange: true
      }

    default:
      return {}
  }
}

const buildCommunityMilestoneDetails = (t, { threshold, reward }) => {
  return {
    title: t('reward-box.luck-is-on-your-side'),
    desc: t('reward-box.you-opened-community', {
      value: formatTicks(threshold)
    }),
    value: reward
  }
}

const findNearestMilestone = (milestones, currentThreshold) => {
  return milestones.reduce((nearest, milestone) => {
    // Check if the milestone threshold is less than or equal to the currentThreshold
    if (milestone.threshold <= currentThreshold) {
      // Update nearest if it is closer to currentThreshold than the previous nearest
      if (!nearest || milestone.threshold > nearest.threshold) {
        return milestone
      }
    }
    return nearest
  }, null)
}

const findExchangeMilestone = (data) => {
  return data?.individual?.find(
    (record) => record?.id === REWARD_BOX_MILESTONE_ID.MILESTONE_2
  )
}

export {
  nextResetTime,
  WALLET_ERRORS,
  buildTransactionErrorMessage,
  isWalletAccountConnected,
  getEthPriceForRewardBox,
  formatTimeForTimer,
  formatMilestones,
  buildPopupDetails,
  buildCommunityMilestoneDetails,
  findNearestMilestone,
  findExchangeMilestone
}
