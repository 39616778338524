import React, { memo } from 'react'

import HowManyPoints from '../../../components/welcome/HowManyPoints'

const HowToEarnRewardsWrapper = ({ tokenSymbol }) => {
  const labelArgs = { point: 50, tokenSymbol }

  return (
    <HowManyPoints
      data={[
        { i18nKey: 'brand:earn_rewards.label1', ...labelArgs },
        { i18nKey: 'brand:earn_rewards.label2', ...labelArgs }
      ]}
    />
  )
}

export default memo(HowToEarnRewardsWrapper)
