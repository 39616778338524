import React, { memo } from 'react'
import { BrandHeader } from 'src/libs/qb-brand-web-components'
import { Link, useLocation } from 'react-router-dom'

import { welcomeScreenLink } from '../../../util/siteLink.helpers'
import UnauthHeaderLogoWrapper from 'wrappers/UnauthHeaderLogoWrapper'

const Header = ({ customCss, iconCss }) => {
  const location = useLocation()
  return (
    <BrandHeader data-testid='header' css={customCss} noCss={!customCss}>
      <Link
        to={`${welcomeScreenLink()}${location.search}`}
        state={location.state}
      >
        <UnauthHeaderLogoWrapper customCss={iconCss} />
      </Link>
    </BrandHeader>
  )
}

export default memo(Header)
